import React from 'react';
import {Col, List, Popconfirm, Row, Tooltip} from 'antd';
import {DeleteOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from "../../store/store";
import * as userSelector from '../../store/redux/selectors/userSelectors';
import {remove} from "../../store/redux/actions/userActions";

// Utils
import {useScreenSizeValidator} from "../../utils/generalUtils";

// Components
import ButtonComponent from "../subComponents/ButtonComponent";


const UserList = () => {
  const dispatch = useAppDispatch();
  const isLG = useScreenSizeValidator('(min-width: 992px)');
  const params = useSelector(userSelector.userParamsSelector)!;
  const response = useSelector(userSelector.allUsersSelector)!;
  const roleTypes = params.roleTypes;

  return (
    <List dataSource={response.rows} grid={{ gutter: 24, column: isLG ? 2 : 1 }}
          renderItem={(user) => (
            <List.Item>
              <Row className={'flex py-2'}>
                <Col sm={24} md={16}>
                  <p className={'font-medium'}>
                    <MailOutlined title={'Correo'} className={'mr-2'} />
                    {user.email}
                  </p>
                  <p>
                    <UserOutlined title={'Rol'} className={'mr-2'} />
                    {roleTypes.find((param) => param.id === user.role)?.value}
                  </p>
                </Col>
                <Col xs={0} md={8} className={'my-auto text-right'}>
                  <Tooltip title={'Eliminar'}>
                    <Popconfirm title={'¿Quieres eliminar este usuario?'} okText={'Confirmar'} cancelText={'Cancelar'}
                                onConfirm={() => dispatch(remove(user.userId))}>
                      <ButtonComponent className={'bg-red-dkt-400 text-white'} icon={<DeleteOutlined />}
                                       text={'Eliminar'} />
                    </Popconfirm>
                  </Tooltip>
                </Col>
                <Col xs={24} md={0} className={'my-auto'}>
                  <Tooltip title={'Eliminar'}>
                    <Popconfirm title={'¿Quieres eliminar este usuario?'} okText={'Confirmar'} cancelText={'Cancelar'}
                                onConfirm={() => dispatch(remove(user.userId))}>
                      <p className={'text-red-dkt-400 cursor-pointer'}>
                        <DeleteOutlined /> Eliminar
                      </p>
                    </Popconfirm>
                  </Tooltip>
                </Col>
              </Row>
            </List.Item>
          )}
    />
  );
}

export default UserList;