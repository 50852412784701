import request from './requestWrapper';

// Types
import {FiltersPaginationType} from '../types/SharedTypes';

function getAll(data?: FiltersPaginationType) {
  return request({
    url: 'shipment/getAll',
    method: 'POST',
    data: data
  });
}
function getShipmentDetail(shipmentId: string) {
  return request({
    url: `shipment/getShipmentDetail/${shipmentId}`,
    method: 'GET',
  });
}

export default {
  getAll, getShipmentDetail
}