import React, {useState} from 'react';
import {Row, Col, Modal, Form, Space} from 'antd';

// Components and Subcomponents
import {DeleteOutlined} from '@ant-design/icons';
import ButtonComponent from '../subComponents/ButtonComponent';
import TitleComponent from '../subComponents/TitleComponent';
import UploadComponent from '../subComponents/UploadComponent';
import SelectComponent from '../subComponents/SelectComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';

// Types
import {CatalogExportType, CatalogParamsType} from '../../types/CatalogTypes';

// Functions and Constants
import {isEmpty} from "../../utils/generalUtils";


type DownloadCatalogModalType = {
  title: string;
  catalogParams: CatalogParamsType;
  onToggleModal: () => void;
  download: (formData: FormData) => void;
};
const DownloadCatalogModal = (props: DownloadCatalogModalType) => {
  const [file, setFile] = useState({ name: '' });
  const [form] = Form.useForm();
  const acceptFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

  const handleSubmit = (values: CatalogExportType) => {
    const formData = new FormData();
    if(file.name !== ''){
      formData.append('file', file as File, file.name);
    }
    formData.append('allianceId', values.allianceId);
    props.download(formData);
    props.onToggleModal();
  };

  return (
    <Modal open={true} closable={false} maskClosable={false} keyboard={false}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cancelar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={props.onToggleModal} />,
             <ButtonComponent key={'submit'} text={'Descargar'}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none ' +
                                'focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick={() => form.submit()}/>
           ]}>
      <Form form={form} layout="vertical" autoComplete="off" onFinish={handleSubmit}>
        <Row>  
          <Col md={24} xs={24} className={'mb-6'}>
            <TitleComponent level={4} text={props.title} className={'mb-1 leading-6 font-medium text-blue-dkt-400'} />
          </Col>
          <Col md={24} xs={24} className={'mb-2'}>
            <FormItemComponent name={"allianceId"} label={"Alianza"} required={true}
                              child={ <SelectComponent className={'[&>div>span]:last:self-center'} list={props.catalogParams.alliances}
                                                    valueIdentifier={'value'}
                                                    idIdentifier={'id'} />
                              }
            />
          </Col>
          <Col md={24} xs={24}>
            <UploadComponent acceptFormat={acceptFormat} onUpload={(file: File) => setFile(file)}
                            supportedFilesText={'Archivos soportados: .xlsx'} />
          </Col>
          {!isEmpty(file.name) &&
            <Col md={24} xs={24} className={'mt-6'}>
              <Space size={'small'} className={'w-full flex [&>div]:contents'}>
                <InputComponent readOnly={true} disabled={true} value={file.name} title={file.name}/>
                <ButtonComponent text={''} icon={<DeleteOutlined/>}
                                className={'bg-orange-dkt-400 text-white hover:bg-orange-dkt-500 focus:outline-none ' +
                                  'focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-300 '}
                                onClick={() => setFile({name: ''})}/>
              </Space>
            </Col>
          }
        </Row>
      </Form>
    </Modal>
  );
}

export default DownloadCatalogModal;