// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import shipmentServices from '../../../api/shipmentServices';

// Types
import {shipmentTypes} from '../types';
import {FiltersPaginationType} from '../../../types/SharedTypes';

// Components and Subcomponents
import {ERROR_MODAL} from '../../../components/subComponents/responseModals';


export const getAll = (data?: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  shipmentServices.getAll(data)
    .then((response) => {
      dispatch({
        type: shipmentTypes.GET_ALL_SHIPMENTS,
        shipmentResponse: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getShipmentDetail = (shipmentId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  shipmentServices.getShipmentDetail(shipmentId)
    .then((response) => {
      dispatch({
        type: shipmentTypes.GET_SHIPMENT_DETAIL,
        shipmentDetail: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}