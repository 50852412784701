import request from './requestWrapper';

// Types
import {UserType} from '../types/UserTypes';
import {PaginationType} from '../types/SharedTypes';


function getParams() {
  return request({
    url: 'users/getParams',
    method: 'GET',
  });
}

function getAll(data?: PaginationType) {
  return request({
    url: 'users/getAll',
    method: 'GET',
    params: data
  });
}

function add(data: UserType) {
  return request({
    url: 'users/create',
    method: 'POST',
    data: data
  });
}

function remove(userId: string) {
  return request({
    url: `users/delete/${userId}`,
    method: 'DELETE',
  });
}

export default {
  getParams, getAll, add, remove
}