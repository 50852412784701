import request from './requestWrapper';

// Types
import {FiltersPaginationType} from '../types/SharedTypes';
import { CategoryType } from '../types/CategoryTypes';

function getParams() {
  return request({
    url: 'category/getParams',
    method: 'GET',
  });
}
function getAll(data?: FiltersPaginationType) {
  return request({
    url: 'category/getAll',
    method: 'POST',
    data: data
  });
}
function getTemplate() {
  return request({
    url: 'category/getTemplate',
    method: 'GET',
  });
}
function add(data: CategoryType) {
  return request({
    url: 'category/create',
    method: 'POST',
    data: data
  });
}
function upload(formData: FormData) {
  return request({
    url: 'category/upload',
    method: 'POST',
    data: formData
  });
}
function update(data: CategoryType) {
  return request({
    url: 'category/update',
    method: 'PUT',
    data: data
  });
}
function remove(categoryId: string) {
  return request({
    url: `category/delete/${categoryId}`,
    method: 'DELETE',
  });
}

export default {
  getParams, getAll, getTemplate, add, upload, update, remove
}