// Types
import {PaymentReducerType} from '../../../types/PaymentTypes';
import {paymentTypes} from '../types';

const initialState = {
  paymentResponse: {
    count: 0,
    rows: []
  },
  paymentDetail: {}
}

const paymentReducer = (state = initialState, action: PaymentReducerType) => {
  switch (action.type) {
    case paymentTypes.GET_ALL_PAYMENTS:
      return {
        ...state,
        paymentResponse: action.paymentResponse
      };
    case paymentTypes.GET_PAYMENT_DETAIL:
      return {
        ...state,
        paymentDetail: action.paymentDetail
      };
    default:
      return state;
  }
};

export default paymentReducer;
