// Libraries
import {Row, Col, Form, Modal} from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../store/store';

// Store
import { 
  create, 
  update,
} from '../../store/redux/actions/categoryActions';

// Types
import { CategoryType } from '../../types/CategoryTypes';
import { FiltersPaginationType } from '../../types/SharedTypes';

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';


type CategoryModalType = {
  paginationData?: FiltersPaginationType;
  categoryToEdit?: CategoryType;
  onToggleModal: (visible: boolean) => void;
};
const CategoryModal = (props: CategoryModalType) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const isEditing = props.categoryToEdit !== undefined;

  const handleSubmit = (values: CategoryType) => {
    if (props.categoryToEdit !== undefined) {
      values.categoryId = props.categoryToEdit.categoryId;
      dispatch(update(values, props.paginationData));
    } else {
      dispatch(create(values, props.paginationData));
    }

    props.onToggleModal(false);
  }

  return (
    <Modal title={`${isEditing ? 'Editar' : 'Crear'} categoría`} open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => props.onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={`${isEditing ? 'Editar' : 'Crear'}`}
                              className={'w-32 h-8 bg-blue-dkt-400 text-white hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"} form={form} onFinish = {handleSubmit}
            initialValues={isEditing ? props.categoryToEdit : {}}
            >
        <Row gutter={10}>
          <Col md={24} xs={24}>
            <FormItemComponent name={'model'} label={'Modelo'} required={true}
                               child={<InputComponent />}
            />
          </Col>
          <Col md={24} xs={24}>
            <FormItemComponent name={'primaryCategory'} label={'Categoría'} required={true}
                               child={<InputComponent/>}
            />
          </Col>  
        </Row>
      </Form>
    </Modal>
  );
}

CategoryModal.propTypes = {
  paginationData: PropTypes.object,
  categoryToEdit: PropTypes.object,
  onToggleModal: PropTypes.func.isRequired,
};

export default CategoryModal;