import { Col, Row, Tabs, TabsProps } from 'antd';

// Components
import TitleComponent from '../subComponents/TitleComponent';
import OrderList from './subComponents/OrderList';
import PaymentList from './subComponents/PaymentList';
import ShipmentList from './subComponents/ShipmentList';

// Constants and Functions
import {routes} from '../../utils/routes';
import {getWindowInformation, redirectWindow} from '../../utils/functions';


const Orders = () => {
  let currentActiveKey = getWindowInformation().pathname;
  if(currentActiveKey === routes.orders.root)
    currentActiveKey = routes.orders.orders;

  const handleChangeTab = (route: string) => {
    redirectWindow(route);
  };

  const tabs: TabsProps['items'] = [
    {
      key: routes.orders.orders,
      label: 'Ordenes',
      children: <OrderList/>,
    },
    {
      key: routes.orders.payments,
      label: 'Pagos',
      children: <PaymentList/>,
    },
    {
      key: routes.orders.shipments,
      label: 'Envios',
      children: <ShipmentList/>,
    },
  ];

  return (
    <Row>
      <Col md={24} sm={24} xs={24}>
        <TitleComponent level={3} text={'Ordenes'} className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <Tabs activeKey={currentActiveKey} centered size={'small'} onChange={handleChangeTab} items={tabs} />
      </Col>
    </Row>
  )
}

export default Orders;