import {StoreType} from '../../../types/SharedTypes';

export const allCatalogSelector = (state: StoreType) => {
  return state.catalogReducer.catalogResponse;
}
export const catalogParamsSelector = (state: StoreType) => {
  return state.catalogReducer.catalogParams;
}
export const productDetailSelector = (state: StoreType) => {
  return state.catalogReducer.productDetail;
}