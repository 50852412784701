import request from './requestWrapper';

// Types
import {FiltersPaginationType} from '../types/SharedTypes';

function upload(formData: FormData) {
  return request({
    url: 'productStore/upload',
    method: 'POST',
    data: formData
  });
}
function getTemplate() {
  return request({
    url: 'productStore/getTemplate',
    method: 'GET',
  });
}

export default {
  upload, getTemplate
}