// Libraries
import React, { useEffect } from 'react';
import {Row, Col, Form, Modal, DatePicker} from 'antd';
import {useAppDispatch} from '../../store/store';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";

// Store
import { update } from '../../store/redux/actions/catalogActions';

// Types
import {AllianceStockType} from "../../types/AllianceStockTypes";

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';


type StockFormModalType = {
  stockToEdit?: AllianceStockType;
  onToggleModal: (visible: boolean) => void;
};
const StockFormModal = (props: StockFormModalType) => {
  dayjs.extend(utc);
  const { RangePicker } = DatePicker;
  const { onToggleModal, stockToEdit } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  useEffect(() => {
    var promotionDates = []
    if(stockToEdit) {
        promotionDates[0] = 
          stockToEdit.promotionStartDate ? dayjs.utc(stockToEdit.promotionStartDate) : null;

        promotionDates[1] = 
          stockToEdit.promotionEndDate ? dayjs.utc(stockToEdit.promotionEndDate) : null;
      
      form.setFieldsValue({
        promotionDates: promotionDates
      });
    }
  }, []);

  const handleSubmit = (stock: AllianceStockType) => {
    stock.catalogId = stockToEdit!.catalogId;
    stock.baseStock = parseInt(stock.baseStock.toString());

    if (stock.promotionDates) {
      stock.promotionStartDate = stock.promotionDates[0];
      stock.promotionEndDate = stock.promotionDates[1];
    }
  
    dispatch(update(stock));
    onToggleModal(false);
  }

  return (
    <Modal title={'Editar stock'} open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={'Editar'}
                              className={'w-32 h-8 bg-blue-dkt-400 text-white hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"} className={'pt-4'}
            form={form} onFinish={handleSubmit} initialValues={stockToEdit}>
        <Row gutter={[12, 0]}>
          <Col md={24} xs={24}>
            <FormItemComponent name={'name'} label={'Nombre'} required={false}
                               child={<InputComponent placeHolder={'Ej: Alianza'}
                                                      disabled={true}
                                                      readOnly={true} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'baseStock'} label={'Stock base'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'price'} label={'Precio descuento'} required={true}
                               child={<InputComponent type={'currency'} prefix={'$'} />}
            />
          </Col>
          <Col md={24} xs={24}>
            <FormItemComponent name={'promotionDates'} label={'Fechas promoción'} required={false}
                               child={<RangePicker showTime allowEmpty={[true, true]}/>}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default StockFormModal;