// Libraries
import {useMediaQuery} from 'react-responsive';
import dayjs from "dayjs";

export const isEmpty = (element: string | object | null | undefined) => {
  return element === undefined || element === 'undefined'
    || element === null || element === 'null' || element === ''
    || (Array.isArray(element) && element.length === 0)
    || (Object.keys(element).length === 0);
};

export const isSignedIn = () => {
  const token = getLocalStorageItem('token');
  const role = getLocalStorageItem('role');
  const name = getLocalStorageItem('name');
  const roleName = getLocalStorageItem('roleName');
  const expiredIn = getLocalStorageItem('expiredIn');
  const incorrectInfo = isEmpty(token) || isEmpty(role) || isEmpty(name) || isEmpty(roleName) || isEmpty(expiredIn);
  const tokenExpired = new Date() > new Date(expiredIn!);

  if(incorrectInfo || tokenExpired) clearStorage();

  return !incorrectInfo && !tokenExpired;
};

/* --------------------- Storage ----------------------- */
export const setSessionStorageItem = (fieldName: string, value: string) => {
  sessionStorage.setItem(fieldName, value);
};
export const setLocalStorageItem = (fieldName: string, value: string) => {
  localStorage.setItem(fieldName, value);
};
export const getSessionStorageItem = (fieldName: string) => {
  return sessionStorage.getItem(fieldName);
};
export const getLocalStorageItem = (fieldName: string) => {
  return localStorage.getItem(fieldName);
};
export const clearStorage = () => {
  sessionStorage.clear();
  localStorage.clear();
};

/* --------------------- Window Actions ----------------------- */
export const getWindowInformation = () => {
  const pathname = window.location.pathname;
  const search = window.location.search;

  return { pathname, search };
};
export const reloadWindow = () => {
  window.location.reload();
};
export const redirectWindow = (pathname: string) => {
  window.location.href = pathname;
};
export const useScreenSizeValidator = (sizeValidator: string) => {
  return useMediaQuery({ query: sizeValidator });
};

/* ----------------------- Currency ------------------------ */
export function currencyFormat(value?: string | number){
  if(value == undefined || value == null) return '-'

  const text = `${value}`
    .replace('.', ',')
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.');

  return `${text}`;
}
export function currencyToNumber(value: string){
  const splitValue = value.split(',');
  return parseFloat(splitValue[0].replaceAll('.', '') + '.' + splitValue[1]);
}

/* --------------------------- Text -------------------------- */
export const removeDiacritics = (text: string) => {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const getDateFormat = (date: string, format: string) => {
  return dayjs(date).format(format);
};

export const getRangeDateFormat = (format: string, dates?: any) => {
  let startDate = dates ? dates[0] ? dayjs(dates[0]).format(format) : '' : ''
  let endDate = dates ? dates[1] ? dayjs(dates[1]).format(format) : '' : ''
  let text = `${startDate} - ${endDate}`;
  return text
};

/* ------------------------- Colors --------------------------- */
export function invertColor(hex?: string){
  if(!hex)
    return '#ffffff'

  if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
  }
  if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  let color = '#' + padZero(r) + padZero(g) + padZero(b)
  return color;
}

export function padZero(str: string, len?: number) {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}