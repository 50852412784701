// Libraries
import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {ConfigProvider} from 'antd';
import esES from 'antd/locale/es_ES';
import {store} from './store/store';

// Styles 
import './styles/index.css';

// Components and Subcomponents
import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ConfigProvider locale={esES}>
      <BrowserRouter basename={'/'}>
        <App />
      </ BrowserRouter>
    </ ConfigProvider>
  </ Provider>
);

reportWebVitals();
