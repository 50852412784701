import request from './requestWrapper';

// Types
import {FiltersPaginationType} from '../types/SharedTypes';

function getAll(data?: FiltersPaginationType) {
  return request({
    url: 'payment/getAll',
    method: 'POST',
    data: data
  });
}
function getPaymentDetail(paymentId: string) {
  return request({
    url: `payment/getPaymentDetail/${paymentId}`,
    method: 'GET',
  });
}

export default {
  getAll, getPaymentDetail
}