// Libraries
import {combineReducers} from 'redux';

// Reducers
import generalReducer from './generalReducer';
import sessionReducer from './sessionReducer';
import userReducer from "./userReducer";
import allianceReducer from "./allianceReducer";
import catalogReducer from "./catalogReducer";
import storeReducer from "./storeReducer";
import categoryReducer from "./categoryReducer";
import orderReducer from "./orderReducer";
import paymentReducer from "./paymentReducer";
import shipmentReducer from "./shipmentReducer";

export default combineReducers({
  generalReducer: generalReducer,
  sessionReducer: sessionReducer,
  userReducer: userReducer,
  allianceReducer: allianceReducer,
  catalogReducer: catalogReducer,
  storeReducer: storeReducer,
  categoryReducer: categoryReducer,
  orderReducer: orderReducer,
  paymentReducer: paymentReducer,
  shipmentReducer: shipmentReducer
});
