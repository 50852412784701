// Libraries
import {Col} from 'antd';
import {
  DownloadOutlined, 
  PlusOutlined, 
  ProfileOutlined, 
  UploadOutlined
} from "@ant-design/icons";

// Components
import ButtonComponent from "../subComponents/ButtonComponent";

// Store
import { useAppDispatch } from '../../store/store';
import { getCategoriesTemplate } from '../../store/redux/actions/categoryActions';


type CategoriesTopButtonsType = {
  isSmallScreen: Boolean,
  onToggleUploadCategoryModal: () => void;
  onToggleCategoryModal: () => void;
};
const CategoriesTopButtons = (props: CategoriesTopButtonsType) => {
  const dispatch = useAppDispatch();

  return (
    <Col md={24} xs={24} className={'my-4'}>
      <ButtonComponent text={props.isSmallScreen ? '' : 'Descargar plantilla'}
                      icon={props.isSmallScreen ? <><DownloadOutlined /> <ProfileOutlined /></> : <DownloadOutlined />}
                      className={`${props.isSmallScreen ? 'w-20' : 'w-fit'} ` +
                        'm-0.5 text-blue-dkt-400 bg-white hover:bg-blue-dkt-100 border-blue-dkt-400 focus:outline-none focus:ring-2 ' +
                        'focus:ring-offset-2 focus:ring-blue-dkt-300 '}
                      onClick={() => dispatch(getCategoriesTemplate())} />
      <ButtonComponent text={props.isSmallScreen ? '' : 'Cargar categorías'}
                      icon={props.isSmallScreen ? <><UploadOutlined /> <ProfileOutlined /></> : <UploadOutlined />}
                      className={`${props.isSmallScreen ? 'w-20' : 'w-fit'} ` +
                        'm-0.5 text-white bg-orange-dkt-400 hover:bg-orange-dkt-500 focus:outline-none focus:ring-2 ' +
                        'focus:ring-offset-2 focus:ring-orange-dkt-300 '}
                      onClick={props.onToggleUploadCategoryModal} />
      <ButtonComponent text={props.isSmallScreen ? '' : 'Nueva categoría'}
                      icon={props.isSmallScreen ? <><PlusOutlined /> <ProfileOutlined /></> :<PlusOutlined />}
                      className={`${props.isSmallScreen ? 'w-20' : 'w-fit'} ` + 
                        'm-0.5 bg-blue-dkt-400 text-white hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                        'focus:ring-offset-2 focus:ring-blue-dkt-300 '}
                      onClick={props.onToggleCategoryModal}/>
    </Col>
  );
}

export default CategoriesTopButtons;