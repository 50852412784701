// Libraries
import React from 'react';
import {message, Upload} from 'antd';
import {InboxOutlined} from '@ant-design/icons';

// Functions and Constants
const { Dragger } = Upload;


export type UploadComponentType = {
  acceptFormat: string;
  supportedFilesText: string;
  className?: string;
  onUpload: (file: File) => void;
};
const UploadComponent = (props: UploadComponentType) => {
  const uploadProps = (acceptFormat: string, callback: (file: File) => void) => {
    return {
      multiple: false,
      showUploadList: false,
      accept: acceptFormat,
      customRequest({ file, onSuccess, onError }: any) {
        let error = false;

        if(file.type === '' || !acceptFormat.includes(file.type)){
          message.error({ content: `El archivo "${file.name}" no está permitido.` });
          error = true;
        } else if(file.size/1024/1024 > 10) {
          message.error({ content: `El archivo "${file.name}" sobrepasa el tamaño permitido (10MB).` });
          error = true;
        }

        if (error) {
          setTimeout(() => { onError('error'); }, 0);
          return;
        }

        setTimeout(() => { onSuccess('ok'); }, 0);
        callback(file);
      },
    };
  }

  return (
    <Dragger {...uploadProps(props.acceptFormat, props.onUpload)} className={props.className}>
      <p className={'text-grey-dkt-500 text-6xl'}>
        <InboxOutlined />
      </p>
      Selecciona un archivo o arrastra el archivo en esta área para cargarlo.
      <p className={'text-grey-dkt-600'}>
        {props.supportedFilesText}
      </p>
    </Dragger>
  );
}

export default UploadComponent;