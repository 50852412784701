import React from 'react';
import {Col, List, Popconfirm, Row, Space, Tooltip} from 'antd';
import {CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, TagOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from "../../store/store";
import * as allianceSelectors from '../../store/redux/selectors/allianceSelectors';
import {remove} from "../../store/redux/actions/allianceActions";

// Utils
import {useScreenSizeValidator} from "../../utils/generalUtils";

// Types
import {AllianceType} from "../../types/AllianceTypes";


type AllianceListType = {
  onToggleModal: (visible: boolean, allianceToEdit: AllianceType) => void;
};
const AllianceList = (props: AllianceListType) => {
  const dispatch = useAppDispatch();
  const isLG = useScreenSizeValidator('(min-width: 992px)');
  const response = useSelector(allianceSelectors.allAllianceSelector)!;

  return (
    <List dataSource={response.rows} grid={{ gutter: 24, column: isLG ? 2 : 1 }}
          renderItem={(alliance) => (
            <List.Item>
              <Row className={'flex py-2'}>
                <Col sm={12} md={16}>
                  <p className={'font-medium'}>
                    <TagOutlined title={'Nombre'} className={'mr-2'} />
                    {alliance.name}
                  </p>
                  <p className={`${alliance.isActive ? 'text-green-dkt-600' : 'text-red-dkt-300'}`}>
                    {alliance.isActive
                      ? <CheckOutlined title={'Estado'} className={'mr-2'} />
                      : <CloseOutlined title={'Estado'} className={'mr-2'} />
                    }
                    {alliance.isActive ? 'Activo' : 'Inactivo'}
                  </p>
                </Col>
                <Col xs={0} md={8} className={'my-auto text-right'}>
                  <Space size={'middle'}>
                    <Tooltip title={'Editar'}>
                      <EditOutlined className={'inline-block mx-2 text-blue-dkt-400'}
                                    onClick={() => props.onToggleModal(true, alliance)} />
                    </Tooltip>
                    <Tooltip title={'Eliminar'}>
                      <Popconfirm title={'¿Quieres eliminar esta alianza?'} okText={'Confirmar'} cancelText={'Cancelar'}
                                  onConfirm={() => dispatch(remove(alliance.allianceId))}>
                        <DeleteOutlined className={'inline-block mx-2 text-red-dkt-400'} />
                      </Popconfirm>
                    </Tooltip>
                  </Space>
                </Col>
                <Col xs={12} md={0} className={'my-auto text-center'}>
                  <Space size={'large'}>
                    <p className={'text-blue-dkt-400 cursor-pointer'}
                       onClick={() => props.onToggleModal(true, alliance)}>
                      <DeleteOutlined /> Editar
                    </p>
                    <Popconfirm title={'¿Quieres eliminar esta alianza?'} okText={'Confirmar'} cancelText={'Cancelar'}
                                onConfirm={() => dispatch(remove(alliance.allianceId))}>
                      <p className={'text-red-dkt-400 cursor-pointer'}>
                        <DeleteOutlined /> Eliminar
                      </p>
                    </Popconfirm>
                  </Space>
                </Col>
              </Row>
            </List.Item>
          )}
    />
  );
}

export default AllianceList;