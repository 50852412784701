import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {Row, Col} from 'antd';
import {useAppDispatch} from '../../store/store';

// Types
import { CategoryType, CategoryParamsType } from '../../types/CategoryTypes';
import { FilterType, FiltersPaginationType, PaginationType } from '../../types/SharedTypes';

// Store
import { getParams, getAll, upload, remove } from "../../store/redux/actions/categoryActions";
import * as categorySelectors from '../../store/redux/selectors/categorySelectors';

// Components and Subcomponents
import TitleComponent from '../subComponents/TitleComponent';
import VerticalFilterComponent from '../subComponents/VerticalFilterComponent';
import TableComponent from '../subComponents/TableComponent';
import CategoriesTopButtons from './CategoriesTopButtons';
import UploadExcelModal from '../subComponents/UploadExcelModal';
import CategoryModal from './CategoryModal';

// Utils
import { useScreenSizeValidator } from '../../utils/generalUtils';
import tableColumns from '../../utils/tableColumns';


const Categories = () => {
  const dispatch = useAppDispatch();
  const categoryParams = useSelector(categorySelectors.categoryParamsSelector)!;
  const response = useSelector(categorySelectors.allCategoriesSelector)!;
  const [paginationData, setPaginationData] = useState<FiltersPaginationType>({
    paginationVm: {
      page: 1,
      size: 10,
    },
    filterVm: {}
  });
  const [categoryModalVisible, setCategoryModalVisible] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState<CategoryType | undefined>(undefined);
  const [showUploadCategorygModal, setShowUploadCategoryModal] = useState(false);
  const [filters, setFilters] = useState<FilterType[]>([
    { name: "models", label: "Modelo:", value: undefined, fieldType: 'input' },
    { name: "primaryCategories", label: "Categoría:", value: undefined, fieldType: 'select',
      items: { list: [], idIdentifier: "id", valueIdentifier: "value" } },
  ]);
  const isSmallScreen = useScreenSizeValidator('(max-width: 576px)');
  
  useEffect(() => {
    dispatch(getParams(paginationData));
  }, [paginationData]);

  useEffect(() => {
    let newFilters = filters;
    Object.keys(categoryParams).map(key => {
      const filterFound = newFilters.find(filter => filter.name === key)!;
      filterFound.items!.list = categoryParams[key as keyof CategoryParamsType];
    });
    setFilters(newFilters);
  }, [categoryParams]);

  const handleRemoveCategory = (categoryId: string) => {
    dispatch(remove(categoryId, paginationData));
  };

  const handleChangePagination = (pagination: PaginationType) => {
    const newPagination = paginationData;
    newPagination.paginationVm.page = pagination.page;
    newPagination.paginationVm.size = pagination.size;
    setPaginationData(newPagination);
    dispatch(getAll(paginationData));
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3} text={'Categorías'} className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={24} xs={24} className={'text-left'}>
        <VerticalFilterComponent filters={filters}
                        paginationData={paginationData}
                        setPaginationData={setPaginationData}
                        additionalContent={
                          <CategoriesTopButtons isSmallScreen={isSmallScreen} 
                                                onToggleUploadCategoryModal={() => setShowUploadCategoryModal(true)}
                                                onToggleCategoryModal={() => {
                                                  setCategoryModalVisible(true);
                                                  setCategoryToEdit(undefined);
                                                }}
                          />
                        }
          />
      </Col>
      <Col md={24} xs={24}>
        <TableComponent pageSize={paginationData.paginationVm.size}
                        currentPage={paginationData.paginationVm.page} total={response.count}
                        dataSource={response.rows} scrollX={500} rowKey={'model'}
                        columns={
                          tableColumns('category',
                            (category: CategoryType) => {
                              setCategoryToEdit(category);
                              setCategoryModalVisible(true);
                            },
                            (categoryId: string) => {
                              handleRemoveCategory(categoryId)
                            }
                          )
                        }
                        onChangePage={handleChangePagination}
        />
      </Col>

      {showUploadCategorygModal && 
        <UploadExcelModal title={'Cargar categorías'} 
                          onToggleModal={() => setShowUploadCategoryModal(false)}
                          upload={(formData) => dispatch(upload(formData, paginationData))}
        />
      }

      {categoryModalVisible && 
        <CategoryModal categoryToEdit={categoryToEdit}
                      paginationData={paginationData} 
                      onToggleModal={setCategoryModalVisible}/>
      }

    </Row>
  );
}

export default Categories;