// Libraries
import React, {ReactElement, useState} from 'react';
import {Col, Form, FormInstance, Space, Tag, DatePicker} from 'antd';
import {ClearOutlined, FilterOutlined} from "@ant-design/icons";

// Components
import FormItemComponent from "./FormItemComponent";
import ButtonComponent from "./ButtonComponent";
import InputComponent from "./InputComponent";
import SelectComponent from "./SelectComponent";

// Types
import {FiltersPaginationType, FilterType} from "../../types/SharedTypes";

// Utils
import {getRangeDateFormat} from '../../utils/generalUtils';

// Constants
import {DATETIME_FORMAT} from '../../utils/constants';


type VerticalFilterComponentType = {
  filters: FilterType[];
  paginationData: FiltersPaginationType;
  additionalContent: ReactElement;
  setPaginationData: React.Dispatch<React.SetStateAction<FiltersPaginationType>>;
  onChangeFilters?: (changedValues: any, form: FormInstance<any>) => void;
};
const VerticalFilterComponent = (props: VerticalFilterComponentType) => {
  const [showFilters, setShowFilters] = useState(false);
  const [form] = Form.useForm();
  const hasFilters = Object.values(props.paginationData.filterVm).filter(value => value !== undefined).length > 0;

  const handleFilter = (values: any) => {
    Object.keys(values).map(key => {
      values[key] = typeof values[key] === 'string' ? [values[key]] : values[key];
    });
    const newPagination = {
      paginationVm: { page: 1, size: 10 },
      filterVm: values
    };
    props.setPaginationData(newPagination);
    setShowFilters(false);
  };

  const handleRemoveFilters = (filterName: string, filterType: string, removeAll: boolean) => {
    const newPagination: FiltersPaginationType = JSON.parse(JSON.stringify(props.paginationData));
    const {filterVm} = newPagination;

    if(removeAll) {
      props.filters.map(filter => filterVm[filter.name] = undefined);
    } else if (filterType === "rangePicker"){
      delete filterVm[filterName];
    }
    else
    {
      const [name, value] = filterName.split('|');
      const itemIndex = filterVm[name].findIndex((item: string) => item === value);

      filterVm[name].splice(itemIndex, 1);
      filterVm[name].length === 0 && delete filterVm[name];

      props.filters.map(filter => filterVm[filter.name] = filterVm[filter.name]);
    }

    props.setPaginationData(newPagination);
    form.setFieldsValue(newPagination.filterVm);
  };

  const getFilterComponent = (filterName: string, filter: FilterType) => {
    switch (filterName) {
      case 'input':
        return <InputComponent type={"text"} className={'rounded border-[#d9d9d9] hover:border-[#4096ff]'} />
      case 'select':
        return <SelectComponent list={filter.items!.list}
                                mode={'multiple'}
                                valueIdentifier={filter.items!.valueIdentifier}
                                idIdentifier={filter.items!.idIdentifier} />
      case 'rangePicker':
        return <DatePicker.RangePicker showTime allowEmpty={[true, true]}/>
      default:
        break;
    }
    return <></>
  }

  const getFilterTag = (key: string) => {
    const values = props.paginationData.filterVm[key];
    if (values) {
      const filter = props.filters.find(filter => filter.name === key)!;
      const [filterList, label] = [filter.items?.list!, filter.label];

      if(filter.fieldType === 'rangePicker'){
        return(
          <Tag className={'my-1'} closable key={filter.name} onClose={() => handleRemoveFilters(filter.name, filter.fieldType, false)}>
            <b>{filter.label}</b> {getRangeDateFormat(DATETIME_FORMAT, values)}
          </Tag>
        );
      }else{
        return(
          values && values.map((value: any) =>
            <Tag closable key={`${key}|${value}`}
                  onClose={() => handleRemoveFilters(`${key}|${value}`, '', false)}>
              <b>{label}</b> {filterList ? filterList.find(item => item.id === value).value : value}
            </Tag>
          )
        );
      }         
    }
  }

  return (
    <Col md={24} xs={24} className={'my-4'}>
      <Col md={24} xs={24} className={'flex'}>
        <Col md={10} sm={14} xs={17} className={'m-auto'}>
          <Space className={"w-full"}>
            <ButtonComponent text={'Filtrar'} icon={<FilterOutlined />}
                             className={'ant-btn-primary'}
                             onClick={() => setShowFilters(true)} />
            {hasFilters &&
              <ButtonComponent text={'Borrar'} icon={<ClearOutlined />}
                               className={'ant-btn-secondary'}
                               onClick={() => handleRemoveFilters('', '', true)} />
            }
          </Space>
        </Col>
        <Col md={14} sm={10} xs={7} className={'text-right m-auto'}>
          {props.additionalContent}
        </Col>
      </Col>

      <Col md={24} xs={24} className={'my-4'}>
        {Object.keys(props.paginationData.filterVm).map(key => getFilterTag(key))}
      </Col>

      {showFilters &&
        <>
          <Col className={"w-full h-full bg-black bg-opacity-20 fixed top-0 left-0 z-10"}
               onClick={() => setShowFilters(false)} />
          <Col className={'w-96 bg-white border border-black rounded p-4 absolute top-9 z-20'}>
            <Form layout={"vertical"} form={form} onFinish={handleFilter}>
              {props.filters.map(filter =>
                <Col md={24} xs={24} key={filter.name}>
                  <FormItemComponent name={filter.name} label={filter.label} required={false}
                                     child={getFilterComponent(filter.fieldType, filter)}
                  />
                </Col>
              )}

              <Col md={24} xs={24} className={"mt-5 text-right"}>
                <ButtonComponent text={'Filtrar'} icon={<FilterOutlined />}
                                 htmlType={"submit"}
                                 className={'ant-btn-primary'} />
              </Col>
            </Form>
          </Col>
        </>
      }
    </Col>
  );
}

export default VerticalFilterComponent;