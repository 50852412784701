// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import orderServices from '../../../api/orderServices';

// Types
import {orderTypes} from '../types';
import {FiltersPaginationType} from '../../../types/SharedTypes';

// Components and Subcomponents
import {ERROR_MODAL} from '../../../components/subComponents/responseModals';


export const getAll = (data?: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  orderServices.getAll(data)
    .then((response) => {
      dispatch({
        type: orderTypes.GET_ALL_ORDERS,
        orderResponse: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getOrderDetail = (orderId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  orderServices.getOrderDetail(orderId)
    .then((response) => {
      dispatch({
        type: orderTypes.GET_ORDER_DETAIL,
        orderDetail: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}