import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";

// Libraries
import {Row, Col, Modal} from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../../store/store';

// Store
import {getOrderDetail} from '../../../store/redux/actions/orderActions';
import * as orderSelectors from '../../../store/redux/selectors/orderSelectors';

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';

// Constans
import {DATETIME_FORMAT} from '../../../utils/constants';

// Utils
import {currencyFormat} from '../../../utils/generalUtils';


type OrderDetailModalType = {
  orderId: string;
  onToggleModal: (visible: boolean) => void;
  onToggleShipmentModal: (visible: boolean) => void;
  setShipmentId: (visible: string) => void;
  onTogglePaymentModal: (visible: boolean) => void;
  setPaymentId: (visible: string) => void;
};
const OrderDetailModal = (props: OrderDetailModalType) => {
  dayjs.extend(utc);
  const dispatch = useAppDispatch();
  const order = useSelector(orderSelectors.orderDetailSelector)!;
  
  useEffect(() => {
    dispatch(getOrderDetail(props.orderId));
  }, []);

  const handleShipmentModal = () => {
    props.setShipmentId(order.externalShipmentId.toString())
    props.onToggleModal(false)
    props.onToggleShipmentModal(true)
  }

  const handlePaymentModal = (externalPaymentId: string) => {
    props.setPaymentId(externalPaymentId.toString())
    props.onToggleModal(false)
    props.onTogglePaymentModal(true)
  }

  return (
    <Modal title={`Orden ${order!.externalOrderId} de ${order.alliance?.name!}`} open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true} width={800}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => props.onToggleModal(false)} />,
           ]}>
      <Row gutter={24}>
        <Col md={15} xs={24}>
          <Row gutter={[12, 12]}>
            <Col md={24} xs={24} className={'border-2 border-blue-dkt-400 border-opacity-20 rounded-md'}>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Fecha de creación externa: </span> {dayjs.utc(order.externalCreationDate).format(DATETIME_FORMAT)}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Fecha de cierre externa: </span> {dayjs.utc(order.externalCloseDate).format(DATETIME_FORMAT)}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Fecha de expiracíon externa: </span> {dayjs.utc(order.externalExpirationDate).format(DATETIME_FORMAT)}
              </Col>
            </Col>
            <Col md={24} xs={24} className={'border-2 border-blue-dkt-400 border-opacity-20 rounded-md'}>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Comprador</span>
              </Col>
                <Col md={24} xs={24}>
                <Col md={24} xs={24}>
                  <span className={'font-semibold'}>Id externo: </span> {order.buyer?.externalBuyerId}
                </Col>
                <Col md={24} xs={24}>
                  <span className={'font-semibold'}>Nickname: </span> {order.buyer?.nickname}
                </Col>
                <Col md={24} xs={24}>
                  <span className={'font-semibold'}>Nombre: </span> {`${order.buyer?.firstName} ${order.buyer?.lastName}`}
                </Col>
              </Col> 
            </Col>
            <Col md={24} xs={24} className={'border-2 border-blue-dkt-400 border-opacity-20 rounded-md'}>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Envío</span>
              </Col>
              <Col md={24} xs={24}>
                <Col md={24} xs={24}>
                  <span className={'font-semibold'}>Id externo: </span> 
                  <a className={' text-blue-dkt-300'} onClick={() => handleShipmentModal()}>{order.externalShipmentId}</a>
                </Col>
              </Col>
            </Col>
            <Col md={24} xs={24} className={'border-2 border-blue-dkt-400 border-opacity-20 rounded-md'}>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Articulos</span>
              </Col>
              {order.items?.map(item => 
                <Col md={24} xs={24} key={item.externalItemId}>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Nombre: </span> {item.itemName}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Codigo: </span> {item.code}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Codigo externo: </span> {item.externalItemId}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Categoria externa: </span> {item.externalCategoryId}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Precio unitario: </span> {`$ ${currencyFormat(item.unitPrice)}`}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Precio unitario completo: </span> {`$ ${currencyFormat(item.fullUnitPrice)}`}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Tarifa de venta: </span> {`$ ${currencyFormat(item.saleFee)}`}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Cantidad: </span> {item.quantity}
                  </Col>
                </Col>)
              }
            </Col>
          </Row>
        </Col>
        <Col md={9} xs={24}>
          <Row gutter={[12, 12]}>
            <Col md={24} xs={24} className={'border-2 border-blue-dkt-400 border-opacity-20 rounded-md'}>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Pago</span>
              </Col>
              <Col md={24} xs={24}>
                <Col md={24} xs={24}>
                  <span className={'font-semibold'}>Monto Total :</span> {`$ ${currencyFormat(order.totalAmount)}`}
                </Col>
                <Col md={24} xs={24}>
                  <span className={'font-semibold'}>Monto Pagado :</span> {`$ ${currencyFormat(order.paidAmount)}`}
                </Col>
              </Col>
            </Col>
            <Col md={24} xs={24} className={'border-2 border-blue-dkt-400 border-opacity-20 rounded-md'}>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Pagos</span>
              </Col>
              {order.payments?.map(payment => 
                <Col md={24} xs={24} key={payment.externalPaymentId}>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Id Externo del pago: </span>         
                    {(payment.paymentId) ? <a className={'text-blue-dkt-300'} onClick={() => handlePaymentModal(payment.externalPaymentId)}>{payment.externalPaymentId}</a>
                      : <span>{payment.externalPaymentId}</span>
                    }
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Tipo de pago: </span> {payment.paymentType}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Tipo de operación: </span> {payment.operationType}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Moneda: </span> {payment.currencyId}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Estado: </span> {payment.status}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Cupon: </span> {`$ ${currencyFormat(payment.couponAmount)}`}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Envío: </span> {`$ ${currencyFormat(payment.shippingCost)}`}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Impuestos: </span> {`$ ${currencyFormat(payment.taxesAmount)}`}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Tarifa Marketplace: </span> {`$ ${currencyFormat(payment.marketplaceFee)}`}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Monto pagado: </span> {`$ ${currencyFormat(payment.totalPaidAmount)}`}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Transacción: </span> {`$ ${currencyFormat(payment.transactionAmount)}`}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Monto de transacción devolución: </span> {`$ ${currencyFormat(payment.transactionAmountRefunded)}`}
                  </Col>
                </Col>)
              }
            </Col>            
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}

OrderDetailModal.propTypes = {
  orderId: PropTypes.string,
  onToggleModal: PropTypes.func.isRequired,
  onToggleShipmentModal: PropTypes.func.isRequired,
  setShipmentId: PropTypes.func.isRequired,
  onTogglePaymentModal: PropTypes.func.isRequired,
  setPaymentId: PropTypes.func.isRequired,
};

export default OrderDetailModal;