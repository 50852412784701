// Libraries
import React, {FunctionComponent} from 'react';
import {Select} from 'antd';
import PropTypes from 'prop-types';

// Utils
import {removeDiacritics} from "../../utils/generalUtils";


type SelectComponentType = {
  allowClear?: boolean;
  mode?: "multiple" | "tags" | undefined;
  placeHolder?: string;
  className?: string;
  idIdentifier: string;
  valueIdentifier: string;
  value?: any;
  list: any[];
  onChange?: (e: string) => void;
};
const SelectComponent: FunctionComponent<SelectComponentType> = (
  {
    allowClear, mode, placeHolder, className, idIdentifier, valueIdentifier,
    value, list, onChange,
  }) => {
  return (
    <Select allowClear={allowClear} showSearch placeholder={placeHolder} value={value}
            optionFilterProp={"children"}
            mode={mode}
            className={'appearance-none block w-full [&>div]:min-h-[36px] [&>div]:px-3 [&>div]:py-0 [&>div]:border ' +
              '[&>div]:shadow-none [&>div]:border-grey-dkt-300 [&>div]:rounded-md [&>div>span]:last:leading-4 [&>*]:sm:text-sm ' +
              className
            }
            filterOption={(input, option) => {
              return removeDiacritics(option!.title!.toLowerCase())!.includes(removeDiacritics(input!.toLowerCase()))
            }}
            onChange={(e) => onChange ? onChange(e) : null}>
      {list.map(item =>
        <Select.Option key={item[idIdentifier]} value={item[idIdentifier]} title={item[valueIdentifier]}>
          {item[valueIdentifier]}
        </Select.Option>
      )}
    </Select>
  );
}

SelectComponent.propTypes = {
  allowClear: PropTypes.bool,
  mode: PropTypes.oneOf(['multiple', 'tags', undefined]),
  placeHolder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.any,
  idIdentifier: PropTypes.string.isRequired,
  valueIdentifier: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};
SelectComponent.defaultProps = {
  allowClear: false,
  mode: undefined,
  placeHolder: 'Selecciona una opción',
  className: '',
  value: undefined,
  idIdentifier: 'id',
  valueIdentifier: 'value',
  list: [],
  onChange: () => {
    return null;
  }
};

export default SelectComponent;