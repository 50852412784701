import React, {useEffect, useState} from 'react';
import {Breadcrumb, Col, ColorPicker, Descriptions, Image, Row, Space, Table, Tag} from 'antd';
import {BarsOutlined} from "@ant-design/icons";
import {BreadcrumbItemType, BreadcrumbSeparatorType} from "antd/es/breadcrumb/Breadcrumb";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";

// Types
import {AllianceStockType} from "../../types/AllianceStockTypes";

// Store
import {getDetail} from "../../store/redux/actions/catalogActions";
import {useAppDispatch} from "../../store/store";
import * as catalogSelectors from "../../store/redux/selectors/catalogSelectors";

// Utils
import {routes} from "../../utils/routes";
import {currencyFormat, redirectWindow} from "../../utils/generalUtils";
import tableColumns from "../../utils/tableColumns";

// Components
import StockFormModal from "./StockFormModal";


const ProductDetail = () => {
  const breadcrumbItems: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = [
    { href: routes.catalog.root, title: <><BarsOutlined /><span>Catálogo</span></> },
    { title: 'Detalle de producto' },
  ];
  const [stockModalVisible, setStockModalVisible] = useState(false);
  const [stockToEdit, setStockToEdit] = useState<AllianceStockType | undefined>(undefined);
  const productDetail = useSelector(catalogSelectors.productDetailSelector)!;
  const {model} = useParams();
  const dispatch = useAppDispatch();
  const renderComponent = productDetail.model !== undefined;

  useEffect(() => {
    model ? dispatch(getDetail(model)) : redirectWindow(routes.catalog.root);
  }, []);

  const handleEditStock = (isVisible: boolean, stock?: AllianceStockType) => {
    setStockModalVisible(isVisible);
    setStockToEdit(stock);
  }

  return (
    <Row>
      {renderComponent &&
        <>
          <Col md={24} xs={24} className={'px-5'}>
            <Breadcrumb items={breadcrumbItems} />
          </Col>

          <Col md={10} xs={24} className={'min-h-96 flex p-5 [&>div]:w-full [&>div]:m-auto static'}>
            <Image src={productDetail.imageUrl} />
          </Col>
          <Col md={14} xs={24} className={'min-h-96 flex p-5'}>
            <Space direction={"vertical"} size={'middle'}>
              <Col md={24} xs={24} className={'text-lg -mb-2 text-blue-dkt-500'}>
                {productDetail.brand}
              </Col>
              <Col md={24} xs={24} className={'font-bold text-lg -mt-2'}>
                {productDetail.name}
              </Col>
              <Col>
                {productDetail.alliances.map(alliance =>
                  <Tag style={{ backgroundColor: alliance.color }} className={`border-none text-white my-1`}
                       key={alliance.allianceId} closable={false}>
                    {alliance.name}
                  </Tag>
                )}
              </Col>
              <Col>
                {productDetail.practices?.map((practice, index) =>
                  <Tag className={'bg-blue-dkt-300 border-blue-dkt-400 text-white my-1'}
                       key={index} closable={false}>
                    {practice}
                  </Tag>
                )}
              </Col>
              <Col md={24} xs={24} className={'text-lg text-blue-dkt-500 font-bold'}>
                {`$ ${currencyFormat(productDetail.price.toString())}`}
              </Col>
              <Col md={24} xs={24} className={'text-sm'}>
                <Space direction={"vertical"} size={'small'}>
                  <Col>
                    <span className={'font-semibold'}>Talla:</span> {productDetail.size}
                  </Col>
                  <Col>
                    <span className={'font-semibold'}>Códigos:</span> {productDetail.codes.join(' - ')}
                  </Col>
                </Space>
              </Col>
              <Col md={24} xs={24} className={'text-md'}>
                {productDetail.description}
              </Col>
            </Space>
          </Col>

          <Col md={24} xs={24} className={'p-5'}>
            <Descriptions bordered column={{ xxl: 4, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }} size={'small'}>
              <Descriptions.Item label="Códigos">{productDetail.codes.join(' - ')}</Descriptions.Item>
              <Descriptions.Item label="Modelo">{productDetail.model}</Descriptions.Item>
              <Descriptions.Item label="Supermodelo">{productDetail.supermodel}</Descriptions.Item>
              <Descriptions.Item label="Tags">{productDetail.tags?.join(' - ')}</Descriptions.Item>
              <Descriptions.Item label="Categorías">{productDetail.categories?.join(' - ')}</Descriptions.Item>
              <Descriptions.Item label="Marca">{productDetail.brand}</Descriptions.Item>
              <Descriptions.Item label="Talla">{productDetail.size}</Descriptions.Item>
              <Descriptions.Item label="Stock">{productDetail.stock}</Descriptions.Item>
              <Descriptions.Item label="Color">
                <Space>
                  <ColorPicker format={'hex'} value={productDetail.color} disabled={true} />
                  <span>HEX: <span>{productDetail.color}</span></span>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Tamaño">{productDetail.dimensions}</Descriptions.Item>
              <Descriptions.Item label="Peso">{productDetail.weight}</Descriptions.Item>
            </Descriptions>
          </Col>

          <Col md={24} xs={24} className={'p-5'}>
            <h1 className={'text-lg font-semibold mb-5'}>Variantes</h1>
            {productDetail.variants?.map(variant =>
              <Table rowKey={'code'} key={variant.code} size={'small'} bordered={true}
                     scroll={{ x:  700 }}
                     caption={
                       <Descriptions key={variant.code} bordered={true} size={'small'}
                                     column={{ xxl: 4, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }}>
                         <Descriptions.Item label="Código">{variant.code}</Descriptions.Item>
                         <Descriptions.Item label="Talla">{variant.size}</Descriptions.Item>
                         <Descriptions.Item label="Color">
                           <Space>
                             <ColorPicker format={'hex'} value={variant.color} disabled={true} />
                             <span>HEX: <span>{variant.color}</span></span>
                           </Space>
                         </Descriptions.Item>
                         <Descriptions.Item label="Tamaño">{variant.dimensions}</Descriptions.Item>
                         <Descriptions.Item label="Peso">{variant.weight}</Descriptions.Item>
                       </Descriptions>
                     }
                     dataSource={variant.stocks}
                     columns={tableColumns(
                       'variants',
                       (stock: AllianceStockType) => handleEditStock(true, stock)
                     )} />
            )}
          </Col>
        </>
      }

      {stockModalVisible &&
        <StockFormModal stockToEdit={stockToEdit} onToggleModal={handleEditStock} />
      }
    </Row>
  );
}

export default ProductDetail;