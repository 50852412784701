import request from './requestWrapper';

// Types
import {PaginationType} from '../types/SharedTypes';
import {AllianceType} from "../types/AllianceTypes";


function getAll(pagination?: PaginationType) {
  return request({
    url: 'alliance/getAll',
    method: 'GET',
    params: pagination
  });
}

function add(alliance: AllianceType) {
  return request({
    url: 'alliance/create',
    method: 'POST',
    data: alliance
  });
}

function update(alliance: AllianceType) {
  return request({
    url: 'alliance/update',
    method: 'PUT',
    data: alliance
  });
}

function remove(allianceId: string) {
  return request({
    url: `alliance/delete/${allianceId}`,
    method: 'DELETE',
  });
}

export default {
  getAll, add, update, remove
}