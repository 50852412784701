/* --------------- General ---------------*/
export const generalTypes = {
  IS_LOADING: 'IS_LOADING',
  SET_CURRENT_ROUTE: 'SET_CURRENT_ROUTE',
};

/* ---------------- Users -----------------*/
export const userTypes = {
  GET_USER_PARAMS: 'GET_USER_PARAMS',
  GET_ALL_USERS: 'GET_ALL_USERS',
};

/* ---------------- Alliances -----------------*/
export const alliancesTypes = {
  GET_ALL_ALLIANCES: 'GET_ALL_ALLIANCES',
};

/* ---------------- Catalog -----------------*/
export const catalogTypes = {
  GET_CATALOG: 'GET_CATALOG',
  GET_CATALOG_PARAMS: 'GET_CATALOG_PARAMS',
  GET_PRODUCT_DETAIL: 'GET_PRODUCT_DETAIL',
};

/* ---------------- Store -----------------*/
export const storeTypes = {
  GET_STORE_PARAMS: 'GET_STORE_PARAMS',
  GET_ALL_STORES: 'GET_ALL_STORES',
  GET_STORE_DETAIL: 'GET_STORE_DETAIL',
};

/* ---------------- Category -----------------*/
export const categoryTypes = {
  GET_CATEGORY_PARAMS: 'GET_CATEGORY_PARAMS',
  GET_ALL_CATEGORIES: 'GET_ALL_CATEGORIES',
};

/* ---------------- Order -----------------*/
export const orderTypes = {
  GET_ALL_ORDERS: 'GET_ALL_ORDERS',
  GET_ORDER_DETAIL: 'GET_ORDER_DETAIL'
};

/* ---------------- Payment -----------------*/
export const paymentTypes = {
  GET_ALL_PAYMENTS: 'GET_ALL_PAYMENTS',
  GET_PAYMENT_DETAIL: 'GET_PAYMENT_DETAIL'
};

/* ---------------- Payment -----------------*/
export const shipmentTypes = {
  GET_ALL_SHIPMENTS: 'GET_ALL_SHIPMENTS',
  GET_SHIPMENT_DETAIL: 'GET_SHIPMENT_DETAIL'
};