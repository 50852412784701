// Libraries
import React from 'react';
import {Row, Col, Form, Modal, Switch, ColorPicker} from 'antd';
import type { Color } from 'antd/es/color-picker';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../store/store';

// Store
import { create, update } from '../../store/redux/actions/allianceActions';

// Types
import {AllianceType} from "../../types/AllianceTypes";

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';


type AllianceFormModalType = {
  allianceToEdit?: AllianceType;
  onToggleModal: (visible: boolean) => void;
};
const AllianceFormModal = (props: AllianceFormModalType) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const isEditing = props.allianceToEdit !== undefined;

  const handleSubmit = (alliance: AllianceType) => {
    if(isEditing){
      alliance.allianceId = props.allianceToEdit!.allianceId!;
      alliance.color = typeof alliance.color != "string" ? (alliance.color as unknown as Color).toHexString() : alliance.color;
      dispatch(update(alliance));
    } else {
      alliance.isActive = true;
      alliance.color = alliance.color ? (alliance.color as unknown as Color).toHexString() : '#000000';
      dispatch(create(alliance));
    }
    props.onToggleModal(false);
  }

  return (
    <Modal title={`${isEditing ? 'Editar' : 'Crear'} alianza`} open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => props.onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={`${isEditing ? 'Editar' : 'Crear'}`}
                              className={'w-32 h-8 bg-blue-dkt-400 text-white hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"} className={'pt-4'}
            form={form} onFinish={handleSubmit} initialValues={isEditing ? props.allianceToEdit! : {}}>
        <Row>
          {isEditing &&
            <Col md={12} xs={24}>
              <FormItemComponent name={'isActive'} required={false} valuePropName={'checked'}
                                 child={<Switch checkedChildren={'Activa'} unCheckedChildren={'Inactiva'}
                                                className={'w-fit'} />}
              />
            </Col>
          }
          <Col md={12} xs={24}>
            <FormItemComponent name={'color'} label={'Color de la alianza'} required={false}
                               child={<ColorPicker format={'hex'} />}
            />
          </Col>
          <Col md={24} xs={24}>
            <FormItemComponent name={'name'} label={'Nombre'} required={true}
                               child={<InputComponent placeHolder={'Ej: Alianza'} />}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

AllianceFormModal.propTypes = {
  allianceToEdit: PropTypes.object,
  onToggleModal: PropTypes.func.isRequired,
};

export default AllianceFormModal;