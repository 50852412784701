// Types
import { CategoryReducerType } from '../../../types/CategoryTypes';
import {categoryTypes} from '../types';

const initialState = {
  categoryResponse: {
    count: 0,
    rows: []
  },
  categoryParams: {
    primaryCategories: []
  },
}

const categoryReducer = (state = initialState, action: CategoryReducerType) => {
  switch (action.type) {
    case categoryTypes.GET_CATEGORY_PARAMS:
      return {
        ...state,
        categoryParams: action.categoryParams
      };
    case categoryTypes.GET_ALL_CATEGORIES:
      return {
        ...state,
        categoryResponse: action.categoryResponse
      };
    default:
      return state;
  }
};

export default categoryReducer;
