import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Col, Layout, Menu} from 'antd';
import {UserSwitchOutlined, BarsOutlined, DeploymentUnitOutlined, LogoutOutlined, ProfileOutlined, ShopOutlined, ContainerOutlined} from '@ant-design/icons';

// Store
import {useAppDispatch} from '../../store/store';
import * as generalSelectors from '../../store/redux/selectors/generalSelectors';
import {setCurrentRoute} from '../../store/redux/actions/generalActions';

// Subcomponents
import ButtonComponent from '../subComponents/ButtonComponent';

// Constants and Functions
import {routes} from '../../utils/routes';
import {clearStorage, getLocalStorageItem, getWindowInformation, reloadWindow} from '../../utils/generalUtils';
const {Sider} = Layout;

const Navbar = () => {
  const [isCollapsed, setCollapsed] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userName = getLocalStorageItem('name');
  const roleName = getLocalStorageItem('roleName');
  let currentRoute = useSelector(generalSelectors.currentRouteSelector) ?? routes.root;
  const secondSlashIndex = currentRoute.indexOf('/', 1);
  currentRoute = secondSlashIndex === -1 ? currentRoute : currentRoute.substring(0, secondSlashIndex);

  useEffect(() => {
    const { pathname } = getWindowInformation();
    dispatch(setCurrentRoute(pathname));
  }, []);

  const handleRouteSelector = (route: string) => {
    dispatch(setCurrentRoute(route));
    navigate(route, { replace: false });
  };

  const handleLogout = () => {
    dispatch(setCurrentRoute(routes.root));
    clearStorage();
    reloadWindow();
  };

  const setNavItems = () => {
    const navItems = [];

    navItems.push({ key: routes.users, icon: <UserSwitchOutlined />, label: isCollapsed ? '' : 'Usuarios' });
    navItems.push({ key: routes.alliances, icon: <DeploymentUnitOutlined />, label: isCollapsed ? '' : 'Alianzas' });
    navItems.push({ key: routes.catalog.root, icon: <BarsOutlined />, label: isCollapsed ? '' : 'Catálogo' });
    navItems.push({ key: routes.stores, icon: <ShopOutlined />, label: isCollapsed ? '' : 'Tiendas' });
    navItems.push({ key: routes.categories, icon: <ProfileOutlined />, label: isCollapsed ? '' : 'Categorías' });
    navItems.push({ key: routes.orders.root, icon: <ContainerOutlined />, label: isCollapsed ? '' : 'Ordenes' });

    return navItems;
  };
  const navItems = setNavItems();

  return (
    <Sider breakpoint={"md"}
           className={'h-screen min-h-screen bg-grey-dkt-800 border-r md:flex md:flex-shrink-0 sticky bottom-0 top-0'}
           onBreakpoint={broken => setCollapsed(broken)}
    >
      <Col className={'flex flex-col pt-4 pb-4'}>
        <Col className="logo p-4 min-h-20">
          <img className={'w-full mx-auto'} alt={''}
               src={'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Decathlon_Logo.svg/2560px-Decathlon_Logo.svg.png'} />
        </Col>
        <Menu mode="inline" selectedKeys={[currentRoute]} items={navItems}
              className={'border-0 bg-grey-dkt-800 text-grey-dkt-300 [&>li]:px-4 [&>li]:text-grey-dkt-300 ' +
                '[&>.ant-menu-item-selected]:bg-grey-dkt-800 [&>.ant-menu-item-selected]:text-white ' +
                '[&>li:not(.ant-menu-item-selected):hover]:bg-grey-dkt-700 [&>li:hover]:text-white ' +
                '[&>li:not(.ant-menu-item-selected):active]:bg-grey-dkt-800 [&>li:after]:border-r-0 ' +
                '[&>.ant-menu-item-selected:after]:border-0 ' +
                `${isCollapsed ? '[&>li>.anticon]:ml-2 ' : '[&>li>.anticon]:ml-0 '}`
              }
              onClick={(item) => handleRouteSelector(item.key)}
        />
      </Col>
      <Col className={'flex border-t border-gray-600 p-4 bg-red bottom-0 absolute w-full'}>
        <Col className={'flex-shrink-0 w-full'}>
          {!isCollapsed &&
            <Col className={'group'}>
              <p className={'text-sm font-medium text-grey-dkt-300 group-hover:text-grey-dkt-200'}>
                {userName}
              </p>
              <p className={'text-sm text-grey-dkt-400 group-hover:text-grey-dkt-200'}>
                {roleName}
              </p>
            </Col>
          }
          <ButtonComponent text={isCollapsed ? '' : 'Cerrar sesión'}
                           icon={<LogoutOutlined />}
                           className={'w-full h-10 bg-orange-dkt-400 hover:bg-grey-dkt-700 text-white ' +
                             `${isCollapsed ? 'mt-4 ' : 'mt-0 '}`
                           }
                           onClick={handleLogout} />
        </Col>
      </Col>
    </Sider>
  );
}

export default Navbar;
