// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import allianceServices from '../../../api/allianceServices';

// Types
import {alliancesTypes} from '../types';
import {PaginationType} from '../../../types/SharedTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';
import {AllianceType} from "../../../types/AllianceTypes";


export const getAll = (pagination?: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  allianceServices.getAll(pagination)
    .then((response) => {
      dispatch({
        type: alliancesTypes.GET_ALL_ALLIANCES,
        allianceResponse: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}

export const create = (alliance: AllianceType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  allianceServices.add(alliance)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll());
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}

export const update = (alliance: AllianceType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  allianceServices.update(alliance)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll());
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}

export const remove = (allianceId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  allianceServices.remove(allianceId)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll());
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}