// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import paymentServices from '../../../api/paymentServices';

// Types
import {paymentTypes} from '../types';
import {FiltersPaginationType} from '../../../types/SharedTypes';

// Components and Subcomponents
import {ERROR_MODAL} from '../../../components/subComponents/responseModals';


export const getAll = (data?: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  paymentServices.getAll(data)
    .then((response) => {
      dispatch({
        type: paymentTypes.GET_ALL_PAYMENTS,
        paymentResponse: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getPaymentDetail = (paymentId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  paymentServices.getPaymentDetail(paymentId)
    .then((response) => {
      dispatch({
        type: paymentTypes.GET_PAYMENT_DETAIL,
        paymentDetail: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}