// Types
import {alliancesTypes} from '../types';
import {AllianceReducerType} from "../../../types/AllianceTypes";

const initialState = {
  allianceResponse: {
    count: 0,
    rows: []
  }
}

const allianceReducer = (state = initialState, action: AllianceReducerType) => {
  switch (action.type) {
    case alliancesTypes.GET_ALL_ALLIANCES:
      return {
        ...state,
        allianceResponse: action.allianceResponse
      };
    default:
      return state;
  }
};

export default allianceReducer;
