// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import storeServices from '../../../api/storeServices';

// Types
import {storeTypes} from '../types';
import {FiltersPaginationType} from '../../../types/SharedTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';
import { StoreType } from '../../../types/StoreTypes';

export const getParams = (pagination?: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));  
  storeServices.getParams()
    .then((response) => {
      dispatch({
        type: storeTypes.GET_STORE_PARAMS,
        storeParams: response.data
      });
      dispatch(getAll(pagination));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getAll = (data?: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  storeServices.getAll(data)
    .then((response) => {
      dispatch({
        type: storeTypes.GET_ALL_STORES,
        storeResponse: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getDetailById = (storeId?: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  storeServices.getDetailById(storeId)
    .then((response) => {
      dispatch({
        type: storeTypes.GET_STORE_DETAIL,
        storeDetailResponse: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const create = (store: StoreType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  storeServices.add(store)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getParams());
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const update = (store: StoreType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  storeServices.update(store)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getParams());
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}