// Types

import {OrderReducerType} from '../../../types/OrderTypes';
import {orderTypes} from '../types';

const initialState = {
  orderResponse: {
    count: 0,
    rows: []
  },
  orderDetail: {}
}

const orderReducer = (state = initialState, action: OrderReducerType) => {
  switch (action.type) {
    case orderTypes.GET_ALL_ORDERS:
      return {
        ...state,
        orderResponse: action.orderResponse
      };
    case orderTypes.GET_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: action.orderDetail
      };
    default:
      return state;
  }
};

export default orderReducer;
