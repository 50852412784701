import React from "react";
import {Col, List, Popconfirm, Row, Space, Tag, Tooltip} from "antd";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";

// Types
import {AllianceStockType} from "../types/AllianceStockTypes";
import {CategoryType} from "../types/CategoryTypes";
import {AllianceType} from "../types/AllianceTypes";
import {OrderOmcType} from "../types/OrderTypes";
import {PaymentOmcType} from "../types/PaymentTypes";
import {ShipmentOmcType} from "../types/ShipmentTypes";

// Utils
import {currencyFormat, invertColor} from "./generalUtils";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";

//Constans
import {DATETIME_FORMAT} from "./constants"

const tableColumns = (
  section: string, 
  callback?: (param?: any) => void, 
  removeCallback?: (param?: any) => void
  ) => {
  dayjs.extend(utc);
  switch (section){
    case 'catalog':
      return [
        { title: '', dataIndex: 'firstColumn', key: 'firstColumn', width: '100px' },
        { title: '', dataIndex: 'secondColumn', key: 'secondColumn', width: '500px' },
      ];
    case 'variants':
      return [
        { title: 'Código', dataIndex: 'code', key: 'code', width: '100px' },
        { title: 'Nombre', dataIndex: 'name', key: 'name', width: '200px' },
        { title: 'Stock', dataIndex: 'stock', key: 'stock', width: '100px' },
        { title: 'Stocks de alianza', dataIndex: 'allianceStocks', key: 'allianceStocks', width: '300px',
          render: (stocks: AllianceStockType[]) =>
            <List itemLayout={"horizontal"} size={'small'} dataSource={stocks}
                  renderItem={(stock) => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <Col>
                            <Tag color={stock.color}>
                              {stock.name}
                            </Tag>
                            <Tooltip title={'Editar'}>
                              <EditOutlined className={'inline-block float-right mx-2 text-blue-dkt-400'}
                                            onClick={() => callback && callback(stock)} />
                            </Tooltip>
                          </Col>
                        }
                        description={
                          <Row >
                            <Col md={24} xs={24}>
                              <Space>
                                <Col>
                                  <span className={'font-semibold'}>Precio descuento:</span> {`$ ${currencyFormat(stock.price.toString())}`}
                                </Col>
                                <Col>
                                  <span className={'font-semibold'}>Stock base:</span> {stock.baseStock}
                                </Col>
                              </Space> 
                            </Col>
                            {stock.promotionStartDate && <Col md={24} xs={24}>
                              <span className={'font-semibold'}>Promoción fecha inicial:</span> {dayjs.utc(stock.promotionStartDate).format(DATETIME_FORMAT)}
                            </Col>}
                            {stock.promotionEndDate && <Col md={24} xs={24}>
                              <span className={'font-semibold'}>Promoción fecha final:</span> {dayjs.utc(stock.promotionEndDate).format(DATETIME_FORMAT)}
                            </Col>}
                          </Row>
                        }
                      />
                    </List.Item>
                  )}
            />
        },
      ];
    case 'category':
      return [
        { title: 'Modelo', dataIndex: 'model', key: 'model', width: '100px' },
        { title: 'Categoría', dataIndex: 'primaryCategory', key: 'primaryCategory', width: '500px' },
        { title: 'Acciones', dataIndex: '', key: 'action', width: '100px',
          render: (category: CategoryType) =>
            <Space>
              <Tooltip title={'Editar'}>
                <EditOutlined className={'inline-block mx-2 text-blue-dkt-400'}
                            onClick={() => callback && callback(category)}/>
              </Tooltip>
              <Popconfirm title={'¿Quieres eliminar esta categoría?'} okText={'Confirmar'} cancelText={'Cancelar'} 
                          onConfirm={() => removeCallback && removeCallback(category.categoryId)}
              >
                <p className={'text-red-dkt-400 cursor-pointer mt-1'}>
                  <Tooltip title={'Eliminar'}>
                    <DeleteOutlined />
                  </Tooltip>
                </p>
              </Popconfirm>
            </Space>
        },
      ];
    case 'orders':
      return [
        { title: 'Orden', dataIndex: 'externalOrderId', key: 'externalOrderId', width: '80px' },
        { title: 'Artículos', dataIndex: 'items', key: 'items', width: '100px' },  
        { title: 'Pagos', dataIndex: 'paymentIds', key: 'paymentIds', width: '100px' },  
        { title: 'Cantidad total', dataIndex: 'totalAmount', key: 'totalAmount', width: '100px' },  
        { title: 'Cantidad Pagada', dataIndex: 'paidAmount', key: 'paidAmount', width: '100px' },  
        { title: 'Fecha creación', dataIndex: 'creationDate', key: 'creationDate', width: '100px',
          render: ((creationDate: string) => <>{dayjs.utc(creationDate).format(DATETIME_FORMAT)}</>)
        },
        { title: 'Alianza', dataIndex: 'alliance', key: 'alliance', width: '100px',
          render: (alliance: AllianceType) => 
            {
              var colorInverted = invertColor(alliance.color);
              return <Tag style={{ backgroundColor: alliance.color, color: colorInverted}}>
                        {alliance.name}
                    </Tag>
            } 
        }, 
        { title: 'Acciones', dataIndex: '', key: 'action', width: '50px',
          render: (order: OrderOmcType) =>
            <Space>
              <Tooltip title={'Ver'}>
                <EyeOutlined className={'inline-block mx-2 text-blue-dkt-400 align-middle'}
                            onClick={() => callback && callback(order.orderId)}/>
              </Tooltip>
            </Space>
        },    
      ];
      case 'payments':
        return [
          { title: 'Pago', dataIndex: 'externalPaymentId', key: 'externalPaymentId', width: '80px' },
          { title: 'Orden', dataIndex: 'externalOrderId', key: 'externalOrderId', width: '80px' },
          { title: 'Fecha creación', dataIndex: 'creationDate', key: 'creationDate', width: '100px',
            render: ((creationDate: string) => <>{dayjs.utc(creationDate).format(DATETIME_FORMAT)}</>)
          },
          { title: 'Alianza', dataIndex: 'alliance', key: 'alliance', width: '100px',
            render: (alliance: AllianceType) => 
              {
                var colorInverted = invertColor(alliance.color);
                return <Tag style={{ backgroundColor: alliance.color, color: colorInverted}}>
                          {alliance.name}
                      </Tag>
              } 
          }, 
          { title: 'Acciones', dataIndex: '', key: 'action', width: '50px',
            render: (payment: PaymentOmcType) =>
              <Space>
                <Tooltip title={'Ver'}>
                  <EyeOutlined className={'inline-block mx-2 text-blue-dkt-400 align-middle'}
                              onClick={() => callback && callback(payment.paymentId)}/>
                </Tooltip>
              </Space>
          },    
        ];
      case 'shipments':
        return [
          { title: 'Envío', dataIndex: 'externalShipmentId', key: 'externalShipmentId', width: '80px' },
          { title: 'Orden', dataIndex: 'externalOrderId', key: 'externalOrderId', width: '80px' },
          { title: 'Fecha creación', dataIndex: 'creationDate', key: 'creationDate', width: '100px',
            render: ((creationDate: string) => <>{dayjs.utc(creationDate).format(DATETIME_FORMAT)}</>)
          },
          { title: 'Alianza', dataIndex: 'alliance', key: 'alliance', width: '100px',
            render: (alliance: AllianceType) => 
              {
                var colorInverted = invertColor(alliance.color);
                return <Tag style={{ backgroundColor: alliance.color, color: colorInverted}}>
                          {alliance.name}
                      </Tag>
              } 
          }, 
          { title: 'Acciones', dataIndex: '', key: 'action', width: '50px',
            render: (shipment: ShipmentOmcType) =>
              <Space>
                <Tooltip title={'Ver'}>
                  <EyeOutlined className={'inline-block mx-2 text-blue-dkt-400 align-middle'}
                              onClick={() => callback && callback(shipment.shipmentId)}/>
                </Tooltip>
              </Space>
          },    
        ];
    default:
      return [];
  }
};

export default tableColumns;