// Types
import {StoreReducerType} from '../../../types/StoreTypes';
import {storeTypes} from '../types';

const initialState = {
  storeResponse: {
    count: 0,
    rows: []
  },
  storeParams: {
    alliances: [],
    stores: []
  },
  storeDetailResponse: {}
}

const storeReducer = (state = initialState, action: StoreReducerType) => {
  switch (action.type) {
    case storeTypes.GET_STORE_PARAMS:
      return {
        ...state,
        storeParams: action.storeParams
      };
    case storeTypes.GET_ALL_STORES:
      return {
        ...state,
        storeResponse: action.storeResponse
      };
    case storeTypes.GET_STORE_DETAIL:
      return {
        ...state,
        storeDetailResponse: action.storeDetailResponse
      };
    default:
      return state;
  }
};

export default storeReducer;
