import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";

// Libraries
import {Row, Col, Modal} from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../../store/store';

// Store
import {getPaymentDetail} from '../../../store/redux/actions/paymentActions';
import * as paymentSelectors from '../../../store/redux/selectors/paymentSelectors';

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';

// Constans
import {DATETIME_FORMAT} from '../../../utils/constants';

// Utils
import {currencyFormat} from '../../../utils/generalUtils';


type PaymentDetailModalType = {
  paymentId: string;
  onToggleModal: (visible: boolean) => void;
  onToggleOrderModal: (visible: boolean) => void;
  setOrderId: (visible: string) => void;
  onToggleShipmentModal: (visible: boolean) => void;
  setShipmentId: (visible: string) => void;
};
const PaymentDetailModal = (props: PaymentDetailModalType) => {
  dayjs.extend(utc);
  const dispatch = useAppDispatch();
  const payment = useSelector(paymentSelectors.paymentDetailSelector)!;
  
  useEffect(() => {
    dispatch(getPaymentDetail(props.paymentId));
  }, []);

  const handleOrderModal = (orderId?: string) => {
    props.setOrderId(orderId ?? "")
    props.onToggleModal(false)
    props.onToggleOrderModal(true)
  }

  return (
    <Modal title={`Pago ${payment!.externalPaymentId} de ${payment.alliance?.name!}`} open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true} width={800}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => props.onToggleModal(false)} />,
           ]}>
      <Row gutter={24}>
        <Col md={14} xs={24}>
          <Row gutter={[12, 12]}>
            <Col md={24} xs={24} className={'border-2 border-blue-dkt-400 border-opacity-20 rounded-md'}>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Fecha de creación externa: </span> {dayjs.utc(payment.dateCreated).format(DATETIME_FORMAT)}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Fecha de aprobación externa: </span> {dayjs.utc(payment.dateApproved).format(DATETIME_FORMAT)}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Fecha de liberación externa: </span> {dayjs.utc(payment.moneyReleaseDate).format(DATETIME_FORMAT)}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Última modificación externa: </span> {dayjs.utc(payment.lastModified).format(DATETIME_FORMAT)}
              </Col>
            </Col>
            <Col md={24} xs={24} className={'border-2 border-blue-dkt-400 border-opacity-20 rounded-md'}>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Id Externo del pago: </span> {payment.externalPaymentId}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Id Externo de la orden: </span>
                {(payment.orderId) ? <a className={'text-blue-dkt-300'} onClick={() => handleOrderModal(payment.orderId)}>{payment.externalOrderId}</a>
                  : <span>{payment.externalOrderId}</span>
                }
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Tipo de pago: </span> {payment.paymentType}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Tipo de operación: </span> {payment.operationType}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Tipo de pago: </span> {payment.paymentType}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Moneda: </span> {payment.currencyId}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Estado: </span> {payment.status}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Detalle de estado: </span> {payment.statusDetail}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Marketplace: </span> {payment.marketplace}
              </Col>
            </Col>
          </Row>
        </Col>
        <Col md={10} xs={24}>
          <Row gutter={[12, 12]}>
            <Col md={24} xs={24} className={'border-2 border-blue-dkt-400 border-opacity-20 rounded-md'}>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Cupon: </span> {`$ ${currencyFormat(payment.couponAmount)}`}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Tarifa del cupon: </span> {`$ ${currencyFormat(payment.couponFee)}`}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Envío: </span> {`$ ${currencyFormat(payment.shippingCost)}`}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Impuestos: </span> {`$ ${currencyFormat(payment.taxesAmount)}`}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Tarifa Marketplace: </span> {`$ ${currencyFormat(payment.marketplaceFee)}`}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Tarifa mercadopago: </span> {`$ ${currencyFormat(payment.mercadopagoFee)}`}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Tarifa de descuento: </span> {`$ ${currencyFormat(payment.discountFee)}`}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Tarifa de financiación: </span> {`$ ${currencyFormat(payment.financeFee)}`}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Monto pagado: </span> {`$ ${currencyFormat(payment.totalPaidAmount)}`}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Transacción: </span> {`$ ${currencyFormat(payment.transactionAmount)}`}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Cantidad neta recibida: </span> {`$ ${currencyFormat(payment.netReceivedAmount)}`}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Devolución: </span> {`$ ${currencyFormat(payment.amountRefunded)}`}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Monto de transacción devolución: </span> {`$ ${currencyFormat(payment.transactionAmountRefunded)}`}
              </Col>
            </Col>            
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}

PaymentDetailModal.propTypes = {
  paymentId: PropTypes.string,
  onToggleModal: PropTypes.func.isRequired,
  onToggleOrderModal: PropTypes.func.isRequired,
  setOrderId: PropTypes.func.isRequired,
  onToggleShipmentModal: PropTypes.func.isRequired,
  setShipmentId: PropTypes.func.isRequired,
};

export default PaymentDetailModal;