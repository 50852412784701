// Libraries
import React from 'react';
import {Row, Col, Form, Modal} from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../store/store';

// Store
import { create } from '../../store/redux/actions/userActions';

// Types
import {UserType} from '../../types/UserTypes';

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';


type UserFormModalType = {
  onToggleModal: (visible: boolean) => void;
};
const UserFormModal = (props: UserFormModalType) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const handleSubmit = (user: UserType) => {
    dispatch(create(user));
    props.onToggleModal(false);
  }

  return (
    <Modal title={'Crear usuario'} open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => props.onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={'Crear'}
                              className={'w-32 h-8 bg-blue-dkt-400 text-white hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"} form={form} onFinish = {handleSubmit}>
        <Row gutter={10}>
          <Col md={24} xs={24}>
            <FormItemComponent name={'email'} label={'Correo'} required={true}
                               child={<InputComponent placeHolder={'Ej: correo@decathlon.com'} />}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

UserFormModal.propTypes = {
  onToggleModal: PropTypes.func.isRequired,
};

export default UserFormModal;