import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";

// Libraries
import {Row, Col, Modal} from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../../store/store';

// Store
import {getShipmentDetail} from '../../../store/redux/actions/shipmentActions';
import * as shipmentSelectors from '../../../store/redux/selectors/shipmentSelectors';

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';

// Constans
import {DATETIME_FORMAT} from '../../../utils/constants';

// Utils
import {currencyFormat} from '../../../utils/generalUtils';


type ShipmentDetailModalType = {
  shipmentId: string;
  onToggleModal: (visible: boolean) => void;
  onToggleOrderModal: (visible: boolean) => void;
  setOrderId: (visible: string) => void;
  onTogglePaymentModal: (visible: boolean) => void;
  setPaymentId: (visible: string) => void;
};
const ShipmentDetailModal = (props: ShipmentDetailModalType) => {
  dayjs.extend(utc);
  const dispatch = useAppDispatch();
  const shipment = useSelector(shipmentSelectors.shipmentDetailSelector)!;

  useEffect(() => {
    dispatch(getShipmentDetail(props.shipmentId));
  }, []);

  const handleOrderModal = (orderId?: string) => {
    props.setOrderId(orderId ?? "")
    props.onToggleModal(false)
    props.onToggleOrderModal(true)
  }

  return (
    <Modal title={`Envío ${shipment!.externalShipmentId} de ${shipment.alliance?.name!}`} open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true} width={800}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => props.onToggleModal(false)} />,
           ]}>
      <Row gutter={24}>
      <Col md={16} xs={24}>
          <Row gutter={[12, 12]}>
            <Col md={24} xs={24} className={'border-2 border-blue-dkt-400 border-opacity-20 rounded-md'}>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Fecha de creación externa: </span> {dayjs.utc(shipment.dateCreated).format(DATETIME_FORMAT)}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Última modificación externa: </span> {dayjs.utc(shipment.lastModified).format(DATETIME_FORMAT)}
              </Col>
            </Col>
            <Col md={24} xs={24} className={'border-2 border-blue-dkt-400 border-opacity-20 rounded-md'}>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Id Externo de la orden: </span> 
                {(shipment.orderId) ? <a className={'text-blue-dkt-300'} onClick={() => handleOrderModal(shipment.orderId)}>{shipment.externalOrderId}</a>
                  : <span>{shipment.externalOrderId}</span>
                }
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Estado: </span> {shipment.status}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Método de seguimiento: </span> {shipment.trackingMethod}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Numero de seguimiento: </span> {shipment.trackingNumber}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Tipo de logística: </span> {shipment.logisticsType}
              </Col>
            </Col>
            <Col md={24} xs={24} className={'border-2 border-blue-dkt-400 border-opacity-20 rounded-md'}>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Fecha de manejo: </span> 
                {shipment.dateHandling ? dayjs.utc(shipment.dateHandling).format(DATETIME_FORMAT) : '-'}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Fecha listo para enviar: </span>
                {shipment.dateReadyToShip ? dayjs.utc(shipment.dateReadyToShip).format(DATETIME_FORMAT) : '-'}
              </Col>
              <Col md={24} xs={24}>
              <span className={'font-semibold'}>Fecha de envío: </span>
                {shipment.dateShipped ? dayjs.utc(shipment.dateShipped).format(DATETIME_FORMAT) : '-'}
              </Col>
              <Col md={24} xs={24}>
              <span className={'font-semibold'}>Fecha primera visita: </span>
                {shipment.dateFirstVisit ? dayjs.utc(shipment.dateFirstVisit).format(DATETIME_FORMAT) : '-'}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Fecha de entrega: </span>
                {shipment.dateDelivered ? dayjs.utc(shipment.dateDelivered).format(DATETIME_FORMAT) : '-'}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Fecha de regreso: </span>
                {shipment.dateReturned ? dayjs.utc(shipment.dateReturned).format(DATETIME_FORMAT) : '-'}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Fecha no entregada: </span>
                {shipment.dateNotDelivered ? dayjs.utc(shipment.dateNotDelivered).format(DATETIME_FORMAT) : '-'}
              </Col>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Fecha cancelada: </span>
                {shipment.dateCancelled ? dayjs.utc(shipment.dateCancelled).format(DATETIME_FORMAT) : '-'}
              </Col>
            </Col>
            <Col md={24} xs={24} className={'border-2 border-blue-dkt-400 border-opacity-20 rounded-md'}>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Remitente</span>
              </Col>
              <Col md={24} xs={24}>
                <Col md={24} xs={24}>
                  <span className={'font-semibold'}>Recibe: </span> {shipment.receiverName}
                </Col>
                <Col md={24} xs={24}>
                  <span className={'font-semibold'}>Dirección: </span> {shipment.address}
                </Col>
                <Col md={24} xs={24}>
                  <span className={'font-semibold'}>Ciudad: </span> {shipment.city}
                </Col>
                <Col md={24} xs={24}>
                  <span className={'font-semibold'}>Departamento: </span> {shipment.state}
                </Col>
                <Col md={24} xs={24}>
                  <span className={'font-semibold'}>País: </span> {shipment.country}
                </Col>
                </Col>
            </Col>
            <Col md={24} xs={24} className={'border-2 border-blue-dkt-400 border-opacity-20 rounded-md'}>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Articulos</span>
              </Col>
              {shipment.items?.map(item => 
                <Col md={24} xs={24} key={item.externalItemId}>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Nombre: </span> {item.itemName}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Codigo externo: </span> {item.externalItemId}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Cantidad: </span> {item.quantity}
                  </Col>
                  <Col md={24} xs={24}>
                    <span className={'font-semibold'}>Dimensiones: </span> {item.dimensions}
                  </Col>
                </Col>)
              }
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row gutter={[12, 12]}>
            <Col md={24} xs={24} className={'border-2 border-blue-dkt-400 border-opacity-20 rounded-md'}>
              <Col md={24} xs={24}>
                <span className={'font-semibold'}>Componentes del costo</span>
              </Col>
              <Col md={24} xs={24}>
                <Col md={24} xs={24}>
                  <span className={'font-semibold'}>Descuento fidelización: </span> {`$ ${currencyFormat(shipment.loyalDiscount)}`}
                </Col>
                <Col md={24} xs={24}>
                  <span className={'font-semibold'}>Descuento especial: </span> {`$ ${currencyFormat(shipment.specialDiscount)}`}
                </Col>
                <Col md={24} xs={24}>
                  <span className={'font-semibold'}>Compensación: </span> {`$ ${currencyFormat(shipment.compensation)}`}
                </Col>
                <Col md={24} xs={24}>
                  <span className={'font-semibold'}>Descuento de brecha: </span> {`$ ${currencyFormat(shipment.gapDiscount)}`}
                </Col>
                <Col md={24} xs={24}>
                  <span className={'font-semibold'}>Relación: </span> {`$ ${currencyFormat(shipment.ratio)}`}
                </Col>
              </Col>
            </Col>          
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}

ShipmentDetailModal.propTypes = {
  shipmentId: PropTypes.string,
  onToggleModal: PropTypes.func.isRequired,
  onToggleOrderModal: PropTypes.func.isRequired,
  setOrderId: PropTypes.func.isRequired,
  onTogglePaymentModal:PropTypes.func.isRequired,
  setPaymentId: PropTypes.func.isRequired,
};

export default ShipmentDetailModal;