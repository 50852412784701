import request from './requestWrapper';

// Types
import {FiltersPaginationType} from '../types/SharedTypes';

function getAll(data?: FiltersPaginationType) {
  return request({
    url: 'order/getAll',
    method: 'POST',
    data: data
  });
}
function getOrderDetail(orderId: string) {
  return request({
    url: `order/getOrderDetail/${orderId}`,
    method: 'GET',
  });
}

export default {
  getAll, getOrderDetail
}