import React, {useEffect, useState} from 'react';
import {Row, Col} from 'antd';
import {useSelector} from 'react-redux';

// Types
import {FilterType, FiltersPaginationType, PaginationType} from '../../../types/SharedTypes';

// Store
import {useAppDispatch} from '../../../store/store';
import {getAll} from '../../../store/redux/actions/orderActions';
import * as orderSelectors from '../../../store/redux/selectors/orderSelectors';

// Components
import TableComponent from '../../subComponents/TableComponent';
import OrderDetailModal from './OrderDetailModal';
import ShipmentDetailModal from './ShipmentDetailModal';
import PaymentDetailModal from './PaymentDetailModal';
import VerticalFilterComponent from '../../subComponents/VerticalFilterComponent';

// Utils
import tableColumns from '../../../utils/tableColumns';


const OrderList = () => {
  const dispatch = useAppDispatch();
  const orders = useSelector(orderSelectors.allOrdersSelector)!;
  const [orderDetailModalVisible, setOrderDetailModalVisible] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [shipmentDetailModalVisible, setShipmentDetailModalVisible] = useState(false);
  const [shipmentId, setShipmentId] = useState('');
  const [paymentDetailModalVisible, setPaymentDetailModalVisible] = useState(false);
  const [paymentId, setPaymentId] = useState('');
  const [paginationData, setPaginationData] = useState<FiltersPaginationType>({
    paginationVm: {
      page: 1,
      size: 10,
    },
    filterVm: {}
  });
  const [filters, setFilters] = useState<FilterType[]>([
    { name: "externalOrderId", label: "Orden:", value: undefined, fieldType: 'input' },
    { name: "orderRangeDates", label: "Rango:", value: undefined, fieldType: 'rangePicker' },
  ]);

  useEffect(() => {
    dispatch(getAll(paginationData));
  }, [paginationData]);
  
  const handleChangePagination = (pagination: PaginationType) => {
    const newPagination = paginationData;
    newPagination.paginationVm.page = pagination.page;
    newPagination.paginationVm.size = pagination.size;
    setPaginationData(newPagination);
    dispatch(getAll(paginationData));
  };

  return (
    <Row>
      <Col md={24} xs={24} className={'text-left'}>
        <VerticalFilterComponent filters={filters}
                        paginationData={paginationData}
                        setPaginationData={setPaginationData}
                        additionalContent={<></>}
          />
      </Col>
      <Col md={24} xs={24}>
        <TableComponent pageSize={paginationData.paginationVm.size}
                        currentPage={paginationData.paginationVm.page} total={orders.count}
                        dataSource={orders.rows} scrollX={500} rowKey={'orderId'}
                        columns={
                          tableColumns('orders',
                            (orderId: string) => {
                              setOrderId(orderId)
                              setOrderDetailModalVisible(true);
                            }
                          )
                        }
                        onChangePage={handleChangePagination}
        />
      </Col>

      {orderDetailModalVisible && 
        <OrderDetailModal orderId={orderId} onToggleModal={setOrderDetailModalVisible}
                          setShipmentId={setShipmentId} onToggleShipmentModal={setShipmentDetailModalVisible}
                          setPaymentId={setPaymentId} onTogglePaymentModal={setPaymentDetailModalVisible}/>
      }
      {shipmentDetailModalVisible && 
        <ShipmentDetailModal shipmentId={shipmentId} onToggleModal={setShipmentDetailModalVisible}
                            setOrderId={setOrderId} onToggleOrderModal={setOrderDetailModalVisible}
                            setPaymentId={setPaymentId} onTogglePaymentModal={setPaymentDetailModalVisible}/>
      }
      {paymentDetailModalVisible && 
        <PaymentDetailModal paymentId={paymentId} onToggleModal={setPaymentDetailModalVisible}
                            setOrderId={setOrderId} onToggleOrderModal={setOrderDetailModalVisible}
                            setShipmentId={setShipmentId} onToggleShipmentModal={setShipmentDetailModalVisible}/>
      }
    </Row>
  );
}

export default OrderList;