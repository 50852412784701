import {StoreType} from '../../../types/SharedTypes';

export const storeParamsSelector = (state: StoreType) => {
  return state.storeReducer.storeParams;
}
export const allStoresSelector = (state: StoreType) => {
  return state.storeReducer.storeResponse;
}
export const storeDetailSelector = (state: StoreType) => {
  return state.storeReducer.storeDetailResponse;
}