import request from './requestWrapper';

// Types
import {FiltersPaginationType} from '../types/SharedTypes';
import { StoreType } from '../types/StoreTypes';

function getParams() {
  return request({
    url: 'store/getParams',
    method: 'GET',
  });
}
function getDetailById(storeId?: string) {
  return request({
    url: `store/getDetailById/${storeId}`,
    method: 'GET',
  });
}
function getAll(data?: FiltersPaginationType) {
  return request({
    url: 'store/getAll',
    method: 'POST',
    data: data
  });
}
function add(data: StoreType) {
  return request({
    url: 'store/create',
    method: 'POST',
    data: data
  });
}
function update(data: StoreType) {
  return request({
    url: 'store/update',
    method: 'PUT',
    data: data
  });
}

export default {
  getParams, getAll, getDetailById, add, update
}