
/* --------------------- Window Actions ----------------------- */
export const getWindowInformation = () => {
  const pathname = window.location.pathname;
  const search = window.location.search;

  return { pathname, search };
};

export const redirectWindow = (pathname: string) => {
  window.location.href = pathname;
};

/* ----------------------- Base 64 to File ------------------------ */
export function base64ToFile(data: string, fileName: string){
  const name = fileName;
  //const name = 'archivo.xlsx';
  const downloadLink = document.createElement('a'); 
  downloadLink.href = 'data:application/octet-stream;base64,' + data;
  downloadLink.download = name;
  downloadLink.click();
  downloadLink.remove();
}
