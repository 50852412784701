import React, {useEffect, useState} from 'react';
import {Row, Col} from 'antd';
import {useSelector} from 'react-redux';

// Types
import {FiltersPaginationType, PaginationType} from '../../../types/SharedTypes';

// Store
import {useAppDispatch} from '../../../store/store';
import {getAll} from '../../../store/redux/actions/paymentActions';
import * as paymentSelectors from '../../../store/redux/selectors/paymentSelectors';

// Components
import TableComponent from '../../subComponents/TableComponent';
import PaymentDetailModal from './PaymentDetailModal';
import OrderDetailModal from './OrderDetailModal';
import ShipmentDetailModal from './ShipmentDetailModal';

// Utils
import tableColumns from '../../../utils/tableColumns';


const PaymentList = () => {
  const dispatch = useAppDispatch();
  const payments = useSelector(paymentSelectors.allPaymentsSelector)!;
  const [paymentDetailModalVisible, setPaymentDetailModalVisible] = useState(false);
  const [orderDetailModalVisible, setOrderDetailModalVisible] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [shipmentDetailModalVisible, setShipmentDetailModalVisible] = useState(false);
  const [shipmentId, setShipmentId] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [paginationData, setPaginationData] = useState<FiltersPaginationType>({
    paginationVm: {
      page: 1,
      size: 10,
    },
    filterVm: {}
  });

  useEffect(() => {
    dispatch(getAll(paginationData));
  }, []);
  
  const handleChangePagination = (pagination: PaginationType) => {
    const newPagination = paginationData;
    newPagination.paginationVm.page = pagination.page;
    newPagination.paginationVm.size = pagination.size;
    setPaginationData(newPagination);
    dispatch(getAll(paginationData));
  };

  return (
    <Row>
      <Col md={24} xs={24}>
        <TableComponent pageSize={paginationData.paginationVm.size}
                        currentPage={paginationData.paginationVm.page} total={payments.count}
                        dataSource={payments.rows} scrollX={500} rowKey={'paymentId'}
                        columns={
                          tableColumns('payments',
                            (paymentId: string) => {
                              setPaymentId(paymentId)
                              setPaymentDetailModalVisible(true);
                            }
                          )
                        }
                        onChangePage={handleChangePagination}
        />
      </Col>

      {orderDetailModalVisible && 
        <OrderDetailModal orderId={orderId} onToggleModal={setOrderDetailModalVisible}
                          setShipmentId={setShipmentId} onToggleShipmentModal={setShipmentDetailModalVisible}
                          setPaymentId={setPaymentId} onTogglePaymentModal={setPaymentDetailModalVisible}/>
      }
      {shipmentDetailModalVisible && 
        <ShipmentDetailModal shipmentId={shipmentId} onToggleModal={setShipmentDetailModalVisible}
                            setOrderId={setOrderId} onToggleOrderModal={setOrderDetailModalVisible}
                            setPaymentId={setPaymentId} onTogglePaymentModal={setPaymentDetailModalVisible}/>
      }
      {paymentDetailModalVisible && 
        <PaymentDetailModal paymentId={paymentId} onToggleModal={setPaymentDetailModalVisible}
                            setOrderId={setOrderId} onToggleOrderModal={setOrderDetailModalVisible}
                            setShipmentId={setShipmentId} onToggleShipmentModal={setShipmentDetailModalVisible}/>
      }
    </Row>
  );
}

export default PaymentList;