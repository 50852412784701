// Types
import {catalogTypes} from '../types';
import {CatalogReducerType} from "../../../types/CatalogTypes";

const initialState = {
  catalogResponse: {
    count: 0,
    rows: []
  },
  catalogParams: {
    brands: [],
    stores: []
  },
  productDetail: {}
}

const catalogReducer = (state = initialState, action: CatalogReducerType) => {
  switch (action.type) {
    case catalogTypes.GET_CATALOG:
      return {
        ...state,
        catalogResponse: action.catalogResponse
      };
    case catalogTypes.GET_CATALOG_PARAMS:
      return {
        ...state,
        catalogParams: action.catalogParams
      };
    case catalogTypes.GET_PRODUCT_DETAIL:
      return {
        ...state,
        productDetail: action.productDetail
      };
    default:
      return state;
  }
};

export default catalogReducer;
