// Libraries
import React from 'react';
import {Layout} from 'antd';
import {useSelector} from 'react-redux';

// Store 
import * as sessionSelectors from './store/redux/selectors/sessionSelectors';
import * as generalSelectors from './store/redux/selectors/generalSelectors';

// Components 
import Router from './components/general/Router';
import Loading from './components/general/Loading';
import Navbar from "./components/general/Navbar";

function App() {
  const isLoggedIn = useSelector(sessionSelectors.isLoggedInSelector);
  const isLoading = useSelector(generalSelectors.isLoadingSelector);

  return (
    <Layout hasSider className={'flex'}>
      {isLoading && <Loading />}
      {isLoggedIn && <Navbar />}

      <Router />
    </ Layout>
  );
}

export default App;
