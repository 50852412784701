// Libraries
import {Navigate, Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Layout} from 'antd';

// Store
import * as sessionSelectors from '../../store/redux/selectors/sessionSelectors';

// Components
import Login from "../session/Login";
import Users from "../users/Users";
import Alliances from "../alliances/Alliances";
import Catalog from "../catalog/Catalog";
import ProductDetail from "../catalog/ProductDetail";
import Stores from '../stores/Stores';
import Categories from '../categories/Categories';
import Orders from '../orders/Orders';

// Constants and Functions
import {routes} from '../../utils/routes';
const {Content} = Layout;

function Router() {
  const isLoggedIn = useSelector(sessionSelectors.isLoggedInSelector);

  return (
    <Content className={`flex flex-col w-0 flex-1 overflow-hidden min-h-screen ${isLoggedIn ? 'py-6 px-8' : ''}`}>
      <Routes>
        {isLoggedIn
          ?
          <>
            <Route path={routes.users} element={<Users />} />
            <Route path={routes.alliances} element={<Alliances />} />
            <Route path={routes.catalog.root} element={<Catalog />} />
            <Route path={routes.catalog.detail} element={<ProductDetail />} />
            <Route path={routes.stores} element={<Stores />} />
            <Route path={routes.categories} element={<Categories />} />
            <Route path={routes.orders.root} element={<Orders />} />
            <Route path={routes.orders.orders} element={<Orders />} />
            <Route path={routes.orders.payments} element={<Orders />} />
            <Route path={routes.orders.shipments} element={<Orders />} />

            <Route path={'*'} element={<Navigate to={routes.users} />} />
          </>
          :
          <>
            <Route path={routes.session.login} element={<Login />} />
            <Route path={routes.session.authentication} element={<Login />} />

            <Route path={'*'} element={<Navigate to={routes.session.login} />} />
          </>
        }
      </Routes>
    </Content>
  );
}

export default Router;
