import React, { useEffect, useState } from 'react';
import {Row, Col} from 'antd';
import {useAppDispatch} from '../../store/store';
import {PlusOutlined, RedoOutlined} from '@ant-design/icons';

// Store
import {getParams} from "../../store/redux/actions/userActions";

// Components and Subcomponents
import TitleComponent from '../subComponents/TitleComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import UserList from "./UserList";
import UserFormModal from "./UserFormModal";


const Users = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
  });
  const [userModalVisible, setUserModalVisible] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getParams(pagination));
  }, [pagination]);

  const handleLoadMore = () => {
    setPagination({...pagination, size: pagination.size + 10 });
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3} text={'Usuarios'} className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={24} xs={24} className={'text-right'}>
        <ButtonComponent text={'Nuevo usuario'}
                         icon={<PlusOutlined />}
                         className={'bg-blue-dkt-400 text-white hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                           'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                         onClick={() => setUserModalVisible(true)}
        />
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <UserList />
      </Col>

      <Col md={24} xs={24} className={'text-center pt-5'}>
        <ButtonComponent text={'Ver más usuarios'} icon={<RedoOutlined />}
                         className={'w-60 h-9 justify-center border border-transparent rounded-md ' +
                           'shadow-sm text-sm font-medium text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                           'focus:ring-offset-2 focus:ring-blue-dkt-300'}
                         onClick={handleLoadMore} />
      </Col>

      {userModalVisible &&
        <UserFormModal onToggleModal={setUserModalVisible} />
      }
    </Row>
  );
}

export default Users;