import React, {useEffect, useState} from 'react';
import {Row, Col} from 'antd';
import {useSelector} from 'react-redux';

// Types
import {FiltersPaginationType, PaginationType} from '../../../types/SharedTypes';

// Store
import {useAppDispatch} from '../../../store/store';
import {getAll} from '../../../store/redux/actions/shipmentActions';
import * as shipmentSelectors from '../../../store/redux/selectors/shipmentSelectors';

// Components
import TableComponent from '../../subComponents/TableComponent';
import ShipmentDetailModal from './ShipmentDetailModal';
import OrderDetailModal from './OrderDetailModal';
import PaymentDetailModal from './PaymentDetailModal';

// Utils
import tableColumns from '../../../utils/tableColumns';


const ShipmentList = () => {
  const dispatch = useAppDispatch();
  const shipments = useSelector(shipmentSelectors.allShipmentsSelector)!;
  const [orderDetailModalVisible, setOrderDetailModalVisible] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [shipmentDetailModalVisible, setShipmentDetailModalVisible] = useState(false);
  const [shipmentId, setShipmentId] = useState('');
  const [paymentDetailModalVisible, setPaymentDetailModalVisible] = useState(false);
  const [paymentId, setPaymentId] = useState('');
  const [paginationData, setPaginationData] = useState<FiltersPaginationType>({
    paginationVm: {
      page: 1,
      size: 10,
    },
    filterVm: {}
  });

  useEffect(() => {
    dispatch(getAll(paginationData));
  }, []);
  
  const handleChangePagination = (pagination: PaginationType) => {
    const newPagination = paginationData;
    newPagination.paginationVm.page = pagination.page;
    newPagination.paginationVm.size = pagination.size;
    setPaginationData(newPagination);
    dispatch(getAll(paginationData));
  };

  return (
    <Row>
      <Col md={24} xs={24}>
        <TableComponent pageSize={paginationData.paginationVm.size}
                        currentPage={paginationData.paginationVm.page} total={shipments.count}
                        dataSource={shipments.rows} scrollX={500} rowKey={'shipmentId'}
                        columns={
                          tableColumns('shipments',
                            (shipmentId: string) => {
                              setShipmentId(shipmentId)
                              setShipmentDetailModalVisible(true);
                            }
                          )
                        }
                        onChangePage={handleChangePagination}
        />
      </Col>
      
      {orderDetailModalVisible && 
        <OrderDetailModal orderId={orderId} onToggleModal={setOrderDetailModalVisible}
                          setShipmentId={setShipmentId} onToggleShipmentModal={setShipmentDetailModalVisible}
                          setPaymentId={setPaymentId} onTogglePaymentModal={setPaymentDetailModalVisible}/>
      }
      {shipmentDetailModalVisible && 
        <ShipmentDetailModal shipmentId={shipmentId} onToggleModal={setShipmentDetailModalVisible}
                            setOrderId={setOrderId} onToggleOrderModal={setOrderDetailModalVisible}
                            setPaymentId={setPaymentId} onTogglePaymentModal={setPaymentDetailModalVisible}/>
      }
      {paymentDetailModalVisible && 
        <PaymentDetailModal paymentId={paymentId} onToggleModal={setPaymentDetailModalVisible}
                            setOrderId={setOrderId} onToggleOrderModal={setOrderDetailModalVisible}
                            setShipmentId={setShipmentId} onToggleShipmentModal={setShipmentDetailModalVisible}/>
      }
    </Row>
  );
}

export default ShipmentList;