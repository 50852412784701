import React, { useEffect, useState } from 'react';
import {Row, Col} from 'antd';
import {useAppDispatch} from '../../store/store';
import {PlusOutlined, RedoOutlined} from '@ant-design/icons';

// Store
import {getAll} from "../../store/redux/actions/allianceActions";

// Types
import {AllianceType} from "../../types/AllianceTypes";

// Components and Subcomponents
import TitleComponent from '../subComponents/TitleComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import AllianceList from "./AllianceList";
import AllianceFormModal from "./AllianceFormModal";


const Alliances = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
  });
  const [allianceModalVisible, setAllianceModalVisible] = useState(false);
  const [allianceToEdit, setAllianceToEdit] = useState<AllianceType | undefined>(undefined);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAll(pagination));
  }, [pagination]);

  const handleLoadMore = () => {
    setPagination({...pagination, size: pagination.size + 10 });
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3} text={'Alianzas'} className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={24} xs={24} className={'text-right'}>
        <ButtonComponent text={'Nueva alianza'}
                         icon={<PlusOutlined />}
                         className={'bg-blue-dkt-400 text-white hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                           'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                         onClick={() => {
                           setAllianceModalVisible(true);
                           setAllianceToEdit(undefined);
                         }}
        />
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <AllianceList onToggleModal={(visible, allianceToEdit) => {
          setAllianceModalVisible(visible);
          setAllianceToEdit(allianceToEdit);
        }} />
      </Col>

      <Col md={24} xs={24} className={'text-center pt-5'}>
        <ButtonComponent text={'Ver más alianzas'} icon={<RedoOutlined />}
                         className={'w-60 h-9 justify-center border border-transparent rounded-md ' +
                           'shadow-sm text-sm font-medium text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                           'focus:ring-offset-2 focus:ring-blue-dkt-300'}
                         onClick={handleLoadMore} />
      </Col>

      {allianceModalVisible &&
        <AllianceFormModal allianceToEdit={allianceToEdit} onToggleModal={setAllianceModalVisible} />
      }
    </Row>
  );
}

export default Alliances;
