// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import userServices from '../../../api/userServices';

// Types
import {userTypes} from '../types';
import {UserType} from '../../../types/UserTypes';
import {PaginationType} from '../../../types/SharedTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';


export const getParams = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.getParams()
    .then((response) => {
      dispatch({
        type: userTypes.GET_USER_PARAMS,
        params: response.data
      });
      dispatch(getAll(data));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}

export const getAll = (data?: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.getAll(data)
    .then((response) => {
      dispatch({
        type: userTypes.GET_ALL_USERS,
        userResponse: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}

export const create = (data: UserType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.add(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll());
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}

export const remove = (userId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.remove(userId)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll());
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}