import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {Row, Col} from 'antd';
import {useAppDispatch} from '../../store/store';
import {PlusOutlined, RedoOutlined} from '@ant-design/icons';

// Types
import { StoreParamsType, StoreType } from '../../types/StoreTypes';
import { FilterType, FiltersPaginationType } from '../../types/SharedTypes';

// Store
import { getAll, getParams } from "../../store/redux/actions/storeActions";
import * as storeSelectors from '../../store/redux/selectors/storeSelectors';

// Components and Subcomponents
import TitleComponent from '../subComponents/TitleComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import StoreList from './StoreList';
import AllianceStoreModal from './AllianceStoreModal';
import VerticalFilterComponent from '../subComponents/VerticalFilterComponent';


const Stores = () => {
  const dispatch = useAppDispatch();
  const storeParams = useSelector(storeSelectors.storeParamsSelector)!;
  const [paginationData, setPaginationData] = useState<FiltersPaginationType>({
    paginationVm: {
      page: 1,
      size: 10,
    },
    filterVm: {}
  });
  const [allianceStoreModalVisible, setAllianceStoreModalVisible] = useState(false);
  const [storeToEdit, setStoreToEdit] = useState<StoreType | undefined>(undefined);

  const [filters, setFilters] = useState<FilterType[]>([
    { name: "alliances", label: "Alianzas:", value: undefined, fieldType: 'select',
      items: { list: [], idIdentifier: "id", valueIdentifier: "value" } },
    { name: "stores", label: "Tiendas:", value: undefined, fieldType: 'select',
      items: { list: [], idIdentifier: "id", valueIdentifier: "value" } },
  ]);
  
  useEffect(() => {
    dispatch(getParams(paginationData));
  }, [paginationData]);

  useEffect(() => {
    let newFilters = filters;
    Object.keys(storeParams).map(key => {
      const filterFound = newFilters.find(filter => filter.name === key)!;
      filterFound.items!.list = storeParams[key as keyof StoreParamsType];
    });
    setFilters(newFilters);
  }, [storeParams]);

  const handleLoadMore = () => {
    const newPagination = paginationData;
    newPagination.paginationVm.page = 1;
    newPagination.paginationVm.size = newPagination.paginationVm.size + 10;
    setPaginationData(newPagination);
    dispatch(getAll(paginationData));
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3} text={'Tiendas'} className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={24} xs={24} className={'text-left'}>
        <VerticalFilterComponent filters={filters}
                        paginationData={paginationData}
                        setPaginationData={setPaginationData}
                        additionalContent={
                          <ButtonComponent text={'Nueva tienda'}
                            icon={<PlusOutlined />}
                            className={'bg-blue-dkt-400 text-white hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                              'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick={() => {
                                setAllianceStoreModalVisible(true);
                                setStoreToEdit(undefined);
                              }}
                          />
                        }
          />
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <StoreList onToggleModal={(visible, storeToEdit) => {
          setAllianceStoreModalVisible(visible);
          setStoreToEdit(storeToEdit);
        }} />
      </Col>

      <Col md={24} xs={24} className={'text-center pt-5'}>
        <ButtonComponent text={'Ver más tiendas'} icon={<RedoOutlined />}
                         className={'w-60 h-9 justify-center border border-transparent rounded-md ' +
                           'shadow-sm text-sm font-medium text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                           'focus:ring-offset-2 focus:ring-blue-dkt-300'}
                         onClick={handleLoadMore} />
      </Col>

      {allianceStoreModalVisible &&
        <AllianceStoreModal storeToEdit={storeToEdit} onToggleModal={setAllianceStoreModalVisible} />
      }
    </Row>
  );
}

export default Stores;