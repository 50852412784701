
// Libraries
import {Col, Table, Image, Space, Tag} from 'antd';
import {useSelector} from "react-redux";

// Types
import {FiltersPaginationType} from "../../types/SharedTypes";

// Utils
import tableColumns from "../../utils/tableColumns";
import {routes} from "../../utils/routes";
import {currencyFormat, invertColor} from "../../utils/generalUtils";

// Store
import * as catalogSelectors from "../../store/redux/selectors/catalogSelectors";

type ProductListType = {
  pagination: FiltersPaginationType;
  onChangePagination: (page: number, pageSize: number) => void;
};
const ProductList = (props: ProductListType) => {
  const {rows, count} = useSelector(catalogSelectors.allCatalogSelector)!;

  const dataBuilder = () => {
    let data: any = [];

    rows.map(product =>
      data.push({
        model: product.model,
        firstColumn:
          <Col md={24} xs={24}>
            <Image src={product.imageUrl} />
          </Col>,
        secondColumn:
          <Col md={24} xs={24} className={'my-2'}>
            <Space direction={'vertical'} size={'small'}>
              <Col md={24} xs={24} className={'text-lg -mb-2 text-blue-dkt-500'}>
                {product.brand}
              </Col>
              <a className={'font-bold text-lg cursor-pointer hover:underline hover:decoration-2'}
                 href={routes.catalog.detail.replace(':model', product.model)}>
                {product.name}
              </a>
              <Col md={24}>
                {product.alliances.map(alliance => {
                    var colorInverted = invertColor(alliance.color);
                    return <Tag style={{ backgroundColor: alliance.color, color: colorInverted}} className={`border-none my-1`}
                                key={alliance.allianceId}
                                closable={false}>
                              {alliance.name}
                            </Tag>
                  }
                )}
              </Col>
              <Col md={24}>
                {product.practices?.map((practice, index) =>
                  <Tag className={'bg-blue-dkt-300 border-blue-dkt-400 text-white my-1'}
                       key={index} closable={false}>
                    {practice}
                  </Tag>
                )}
              </Col>
              <Col md={24} xs={24} className={'text-lg text-blue-dkt-500 font-bold'}>
                {`$ ${currencyFormat(product.price.toString())}`}
              </Col>
              <Space size={'large'}>
                <Col>
                  <span className={'font-semibold text-sm'}>Códigos: </span> {`${product.codes.join(' - ') ?? '-'}`}
                </Col>
                <Col>
                  <span className={'font-semibold text-sm'}>Modelo: </span> {`${product.model ?? '-'}`}
                </Col>
                <Col>
                  <span className={'font-semibold text-sm'}>Supermodelo: </span> {`${product.supermodel ?? '-'}`}
                </Col>
                <Col md={24}>
                  <span className={'font-semibold text-sm'}>Stock: </span> {product.stock ?? '-'}
                </Col>
              </Space>
            </Space>
          </Col>
      })
    );

    return data;
  };

  return (
    <Table scroll={{ x: 700 }} showHeader={false} rowKey={'model'} size={'small'}
           columns={tableColumns('catalog')} dataSource={dataBuilder()}
           pagination={{
             pageSize: props.pagination.paginationVm.size,
             current: props.pagination.paginationVm.page,
             showSizeChanger: true,
             total: count,
             onChange: (page, pageSize) => props.onChangePagination(page, pageSize)
           }}
    />
  );
}

export default ProductList;