// Types

import {ShipmentReducerType} from '../../../types/ShipmentTypes';
import {shipmentTypes} from '../types';

const initialState = {
  shipmentResponse: {
    count: 0,
    rows: []
  },
  shipmentDetail: {}
}

const shipmentReducer = (state = initialState, action: ShipmentReducerType) => {
  switch (action.type) {
    case shipmentTypes.GET_ALL_SHIPMENTS:
      return {
        ...state,
        shipmentResponse: action.shipmentResponse
      };
    case shipmentTypes.GET_SHIPMENT_DETAIL:
      return {
        ...state,
        shipmentDetail: action.shipmentDetail
      };
    default:
      return state;
  }
};

export default shipmentReducer;
