import React, {useEffect} from 'react';
import {Col, Row} from 'antd';

// Components and Subcomponents
import ButtonComponent from '../subComponents/ButtonComponent';
import TitleComponent from '../subComponents/TitleComponent';

// Constants and Functions
import {routes} from '../../utils/routes';
import {getWindowInformation, redirectWindow, setLocalStorageItem} from '../../utils/generalUtils';

const Login = () => {
  useEffect(() => {
    const { pathname, search } = getWindowInformation();

    if(pathname === routes.session.authentication){
      const urlParams = new URLSearchParams(search);
      Array.from(urlParams).map(param =>
        setLocalStorageItem(param[0], param[1])
      )
      redirectWindow(routes.root);
    }
  }, []);

  const handleLogin = () => {
    redirectWindow(routes.session.oauth);
  };

  return (
    <Row className={'session-container flex min-h-screen bg-grey-dkt-100 bg-no-repeat bg-cover'}
         style={{ backgroundImage: 'url(\'https://idpdecathlon.oxylane.com/assets/images/3.jpg\')' }}>
      <Col className={'sm:mx-auto sm:w-full sm:max-w-md m-auto bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'}>
        <Col md={24}>
          <img alt={''}
               src={'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Decathlon_Logo.svg/2560px-Decathlon_Logo.svg.png'} />
        </Col>
        <Col md={24}>
          <TitleComponent level={2} text={'Inicia Sesión'}
                          className={'my-8 text-center text-grey-dkt-400'} />
        </Col>
        <Col md={24}>
          <ButtonComponent text={'Ingresa con cuenta de Decathlon'}
                           className={'w-full p-2 text-white bg-orange-dkt-400 hover:bg-orange-dkt-500 focus:outline-none ' +
                             'focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-300 '
                           }
                           onClick={handleLogin}
          />
        </Col>
      </Col>
    </Row>
  );
}

export default Login;